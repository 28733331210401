import { createRouter, createWebHashHistory , RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
// 判断是否登录
router.beforeEach((to, from, next) => {
  // const isLogin = localStorage.getItem('token') ? true : false;
  // console.log(to.path);
  
  // if (to.path == "/login" || to.path == "/register") {
  //   next();
  // } else {
  //   isLogin ? next() : next("/login");
  // }
  next();
});

export default router;
