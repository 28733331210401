<template>
  <router-view />
</template>

<style lang="scss">
:root { 
  --el-color-primary:#3468F7 !important;
  // --el-color-primary-light-3:#6852A0 !important;
  // --el-color-primary-dark-2:#6852A0 !important;
  --el-card-padding: 10px !important;
  --el-bg-color-overlay: #EEEEEE !important;
}
</style>
