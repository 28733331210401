import { createStore } from "vuex";

export default createStore({
  state: {
    // baseURL: "http://192.168.66.9:7000/api/",
    // baseURL: "https://chatlibrary.newacademic.net/api/",
    baseURL: window.location.origin + "/api/",
    // wssURL: "ws://192.168.66.9:7001",
    // wssURL: "wss://chatlibrary.newacademic.net",
    wssURL: "wss://" + window.location.host,
    isConnected: false, //连接状态
    isConnected2: false, //连接状态
    setRobotData: "", //机器人数据
    setRobotData2: "", //机器人数据
    qatimeLoop: null, // 执行的getqatime定时器
  },
  getters: {},
  mutations: {
    setConnected(state: any, isConnected: boolean) {
      state.isConnected = isConnected;
    },
    setConnected2(state: any, isConnected: boolean) {
      state.isConnected2 = isConnected;
    },
    setQatimeLoop(state: any, status: boolean) {
      state.qatimeLoop = status;
    },
  },
  actions: {
    connect({ commit }: { commit: any }) {
      // 连接成功后，将 isConnected 状态设置为 true
      commit("setConnected", true);
    },
    disconnect({ commit }: { commit: any }) {
      // 断开连接或退出登录时，将 isConnected 状态设置为 false
      commit("setConnected", false);
    },
    connect2({ commit }: { commit: any }) {
      // 连接成功后，将 isConnected 状态设置为 true
      commit("setConnected2", true);
    },
    disconnect2({ commit }: { commit: any }) {
      // 断开连接或退出登录时，将 isConnected 状态设置为 false
      commit("setConnected2", false);
    },
    closeQatimeLoop({ commit }: { commit: any }) {
      commit("setQatimeLoop", false);
    },
  },
  modules: {},
});
