/* eslint-disable */
<template>
  <div class="common-layout" style="background-color: #fff" ref="aside">
    <el-container style="height: 100vh">
      <el-header style="height: 50px">
        <div class="header">
          <div class="logo">
            <img src="../assets/img/logod.png" alt="" />
            <span style="font-size: 22px">ChatChats 畅聊</span>
            <!-- <span>专业大模型集合平台</span> -->
          </div>
          <div>
            <el-popconfirm
              v-if="token"
              confirm-button-text="退出"
              cancel-button-text="取消"
              title="退出登录？"
              @confirm="confirmEvent"
            >
              <template #reference>
                <div>
                  {{ username }}
                </div>
              </template>
            </el-popconfirm>
            <div v-else>
              <el-button
                type="text"
                style="font-size: 12px; color: #959595"
                @click="registerHelp"
                >注册教程</el-button
              >
              <el-button type="primary" @click="login">登录</el-button>
              <el-button type="" @click="register">注册</el-button>
            </div>
          </div>
        </div>
      </el-header>
      <section class="product-card" v-if="token">
        <ul>
          <li
            v-for="(opt, index) in productCards"
            :key="index"
            @click="openCardUrl(opt.role!, opt.path!)"
          >
            <div
              :class="['product-card-li', 'product-card-li-' + index]"
              v-if="userRole[opt.role]"
            >
              <div class="product-card__imgbox">
                <img :src="opt.image_url" />
              </div>
              <div class="product-card__titles">
                <p>{{ opt.title_en }}</p>
                <p>{{ opt.title_cn }}</p>
              </div>
            </div>
          </li>
        </ul>
      </section>
      <el-container class="container" v-if="token">
        <el-aside width="280px">
          <div class="aside" style="color: #333">
            <div class="homebutton" @click="handleCreateDialog">
              <img src="../assets/img/u16.png" alt="" /> 创建新对话
            </div>
            <el-collapse accordion>
              <el-collapse-item name="3">
                <template #title>
                  <img
                    src="../assets/img/u61.png"
                    alt=""
                    style="width: 20px; margin-right: 10px; margin-left: 5px"
                  />
                  基础模型
                </template>
                <div
                  class="mychat"
                  v-for="(item, index) in robot.asideList"
                  data-type="robot"
                  v-show="item.name"
                  :class="item.id === infojson.json.id ? 'mychat_active' : ''"
                  :data-id="item.id"
                  :key="index"
                  @click="tab(item, 'new-chat')"
                >
                  <img v-show="item.logo" :src="origin + item.logo" alt="" />
                  {{ item.name }}
                </div>
              </el-collapse-item>

              <el-collapse-item name="2">
                <template #title>
                  <img
                    src="../assets/img/u63.png"
                    alt=""
                    style="width: 20px; margin-right: 10px; margin-left: 5px"
                  />
                  应用场景
                </template>
                <el-collapse accordion v-loading="maskrobotListdialog">
                  <el-collapse-item
                    :name="item.name"
                    v-for="(item, index) in robot.maskList"
                    :key="index"
                  >
                    <template #title>
                      <div style="padding-left: 10px">
                        {{ item.name }}
                      </div>
                    </template>
                    <div
                      class="mychat sider-chat-item"
                      v-for="(items, indexs) in item.masks"
                      :data-id="items.id"
                      :data-name="items.title"
                      data-type="mask"
                      :key="indexs"
                      @click="masktab(items.id)"
                    >
                      <img
                        v-show="items.logo"
                        :src="origin + items.logo"
                        alt=""
                      />
                      {{ items.title }}
                      <img
                        style="margin-left: 20px"
                        v-show="mathnowtime(items.create_time)"
                        src="../assets/img/new.png"
                      />
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-collapse-item>

              <el-collapse-item name="4">
                <template #title>
                  <img
                    src="../assets/img/u62.png"
                    alt=""
                    style="width: 20px; margin-right: 10px; margin-left: 5px"
                  />
                  我创建的
                </template>
                <div
                  class="mychat"
                  v-for="(item, index) in robot.myrobotList"
                  :data-id="item.id"
                  data-type="robot"
                  :key="index"
                  @click="tab(item, 'new-chat')"
                  v-show="item.name"
                >
                  <img v-show="item.logo" :src="origin + item.logo" alt="" />
                  {{ item.name }}
                </div>
              </el-collapse-item>

              <!-- <el-collapse-item name="1">
                <template #title>
                  <img
                    src="../assets/img/pen.png"
                    alt=""
                    style="width: 20px; margin-right: 10px; margin-left: 5px"
                  />
                  智能工具
                  <img
                    style="margin-left: 20px; width: 25px"
                    v-show="mathnowtime('2024-04-12')"
                    src="../assets/img/new.png"
                  />
                </template>
                <div class="mychat" @click="toOther('ppt')">
                  <div style="width: 20px"></div>
                  智能PPT
                  <img
                    style="margin-left: 20px"
                    v-show="mathnowtime('2024-04-12')"
                    src="../assets/img/new.png"
                  />
                </div> -->
              <!-- <div class="mychat" @click="toOther('model')" >
                  <img src="" alt="" />
                  生图模型
                </div> -->
              <!-- </el-collapse-item> -->
            </el-collapse>
            <div draggable="false" class="addrobot" @click="openDrawer">
              <img style="width: 20px" src="../assets/img/u21.png" alt="" />
              创建机器人
            </div>
            <div draggable="false" class="addrobot" @click="openmyrobotcard">
              <img style="width: 20px" src="../assets/img/u25.png" alt="" />
              我的机器人
            </div>
            <div
              draggable="false"
              class="addrobot"
              @click="historychat('chat')"
            >
              <img style="width: 20px" src="../assets/img/u30.png" alt="" />
              聊天记录
            </div>
            <!-- <div draggable="false" class="addrobot" @click="moretype('more')">
              <el-icon style="margin: 0 10px">
                  <SwitchFilled />
              </el-icon>对照问答模式
            </div> -->
          </div>
        </el-aside>
        <el-main style="padding: 0">
          <div v-if="rightshow == 3" style="width: 100%; height: 100%">
            <div class="item_card1" style="width: 100%; height: 100%">
              <el-card
                class="box-card"
                body-class="box-card-body"
                style="width: 100%"
                v-loading="chatpageloading"
              >
                <template #header>
                  <div class="card-header">
                    <span> 聊天记录 </span>
                  </div>
                </template>
                <div
                  style="
                    width: 100%;
                    height: calc(100% - 41px);
                    overflow-y: auto;
                  "
                  ref="historyheight"
                >
                  <div
                    v-if="historyshowselect.list?.name"
                    class="historyselect"
                  >
                    <div
                      :class="historyselecttrue ? 'historyactive' : ''"
                      @click="historyselectclick(historyshowselect.list)"
                    >
                      {{ historyshowselect.list.name }}
                    </div>
                    <div
                      :class="historyselecttrue == false ? 'historyactive' : ''"
                      @click="historyselectclick('all')"
                    >
                      全部
                    </div>
                  </div>
                  <div
                    v-for="(item, index) in robot.chatListse"
                    :key="index"
                    v-show="item.robot_name && historyselecttrue == true"
                    :data-id="item.id"
                    class="mychat"
                    style="
                      border-bottom: 1px solid #e4e7ed;
                      width: 50%;
                      margin: 0 auto;
                    "
                    @click.stop="chatidopen(item)"
                  >
                    <img
                      :src="origin + item.logo"
                      alt=""
                      style="width: 50px; height: 50px; background-color: #fff"
                    />
                    <div
                      :data-id="item.id"
                      style="min-width: calc(100% - 50px)"
                    >
                      <p style="display: flex; justify-content: space-between">
                        {{ item.robot_name }}
                        <span
                          >{{ item.history.create_time
                          }}<el-icon><ArrowRight /></el-icon
                        ></span>
                      </p>
                      <p :data-id="item.id">{{ item.history.answer }}</p>
                    </div>
                    <div class="mychat_del" @click.stop="">
                      <el-popconfirm
                        width="220"
                        confirm-button-text="确定"
                        cancel-button-text="取消"
                        @confirm="delchats(item.id, index, robot.chatListse)"
                        icon-color="#626AEF"
                        title="删除该聊天"
                      >
                        <template #reference>
                          <div class="mychat_del_div">
                            <el-icon color="#2454FF">
                              <MoreFilled />
                            </el-icon>
                          </div>
                        </template>
                      </el-popconfirm>
                    </div>
                  </div>
                  <div
                    :data-id="item.id"
                    class="mychat mychathistory"
                    style="
                      border-bottom: 1px solid #e4e7ed;
                      width: 50%;
                      margin: 0 auto;
                    "
                    v-for="(item, index) in robot.chatList"
                    :key="index"
                    @click.stop="chatidopen(item)"
                    v-show="item.robot_name && historyselecttrue == false"
                  >
                    <img
                      :src="origin + item.logo"
                      alt=""
                      style="width: 50px; height: 50px; background-color: #fff"
                    />
                    <div
                      :data-id="item.id"
                      style="min-width: calc(100% - 70px)"
                    >
                      <p style="display: flex; justify-content: space-between">
                        {{ item.robot_name
                        }}<span style="font-size: 14px"
                          >{{ item.history.create_time
                          }}<el-icon><ArrowRight /></el-icon
                        ></span>
                      </p>
                      <p :data-id="item.id">{{ item.history.answer }}</p>
                    </div>
                    <div class="mychat_del" @click.stop="">
                      <el-popconfirm
                        width="220"
                        confirm-button-text="确定"
                        cancel-button-text="取消"
                        @confirm="delchats(item.id, index, robot.chatList)"
                        icon-color="#626AEF"
                        title="删除该聊天"
                      >
                        <template #reference>
                          <div class="mychat_del_div">
                            <el-icon color="#2454FF">
                              <MoreFilled />
                            </el-icon>
                          </div>
                        </template>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
          <div v-if="rightshow == 2" style="height: 100%">
            <div class="card1">
              <div class="item_card" style="width: 100%; height: 100%">
                <div class="card_clum" style="width: 100%">
                  <div class="text item">
                    <div
                      class="answer"
                      style="width: 100%; margin-bottom: 0; max-width: 100%"
                    >
                      <div class="moreanswer">
                        <el-card
                          style="
                            width: 49%;
                            height: 100%;
                            margin-bottom: 10px;
                            border-radius: 20px;
                            padding: 0 10px;
                          "
                          v-for="(item, index) in selectrobotlist.list"
                          :key="index"
                        >
                          <template #header>
                            <div class="card-header">
                              <span>
                                <!-- <img v-show="item.path" :src="item.path" alt="" /> -->
                                {{ item.name }}
                                <el-button
                                  @click="addselectlist(item)"
                                  plain
                                  class="button"
                                  style="margin-left: 5px"
                                  v-if="selectrobotlist.list.length == 1"
                                >
                                  添加对照模型
                                </el-button>
                              </span>
                              <el-button
                                @click="closemoretype(item)"
                                plain
                                class="button"
                                v-if="selectrobotlist.list.length == 1"
                              >
                                关闭对照模式
                              </el-button>
                              <el-button
                                v-else
                                title="关闭"
                                @click="closerobot(index)"
                                class="button"
                                text
                              >
                                <el-icon>
                                  <CloseBold />
                                </el-icon>
                              </el-button>
                            </div>
                          </template>
                          <div
                            class="text item"
                            :ref="'questionref' + index"
                            style="height: inherit"
                            v-if="morechattype == 'robot' && index == 0"
                          >
                            <div
                              v-for="(item, index) in answerTemp1"
                              :key="index"
                            >
                              <div
                                v-if="item.qus && item.qus != '开启全新对话'"
                                class="qus"
                              >
                                <div style="white-space: pre-wrap">
                                  {{ item.qus }}
                                </div>
                              </div>
                              <div
                                v-if="item.qus && item.qus == '开启全新对话'"
                              >
                                <el-divider>
                                  <span
                                    style="
                                      color: #595757;
                                      white-space: pre-wrap;
                                    "
                                    >{{ item.qus }}</span
                                  >
                                </el-divider>
                              </div>
                              <div
                                v-if="
                                  item.answer.length > 0 && item.answer[0].text
                                "
                                class="answer"
                                style="max-width: 88%"
                              >
                                <img :src="origin + item.logo" alt="" />
                                <div
                                  class="markdown-body"
                                  style="
                                    background-color: #f2f2f2;
                                    padding: 10px;
                                  "
                                >
                                  <div
                                    style="overflow-x: auto"
                                    v-html="item.answer[item.current].text"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <!-- <div>测试1111</div> -->
                          </div>
                          <div
                            class="text item"
                            :ref="'questionref' + index"
                            style="height: inherit"
                            v-if="
                              (morechattype == 'chatandrobot' ||
                                morechattype == 'chat') &&
                              index == 0
                            "
                          >
                            <div
                              v-for="(item, index) in answer[robot_chat_id_one]"
                              :key="index"
                            >
                              <div
                                v-if="item.qus && item.qus != '开启全新对话'"
                                class="qus"
                              >
                                <div style="white-space: pre-wrap">
                                  {{ item.qus }}
                                </div>
                              </div>
                              <div
                                v-if="item.qus && item.qus == '开启全新对话'"
                              >
                                <el-divider>
                                  <span
                                    style="
                                      white-space: pre-wrap;
                                      color: #595757;
                                    "
                                    >{{ item.qus }}</span
                                  >
                                </el-divider>
                              </div>
                              <div
                                v-if="
                                  item.answer.length > 0 && item.answer[0].text
                                "
                                class="answer"
                                style="max-width: 88%"
                              >
                                <img :src="origin + item.logo" alt="" />
                                <div
                                  class="markdown-body"
                                  style="
                                    background-color: #f2f2f2;
                                    padding: 10px;
                                  "
                                >
                                  <div
                                    v-html="item.answer[item.current].text"
                                  ></div>
                                  <div v-if="item.iconShow">
                                    <el-divider
                                      style="margin: 5px 0 8px 0"
                                      border-style="dashed"
                                    />
                                    <div class="icon-box">
                                      <div
                                        v-if="item.answer.length > 1"
                                        class="pagination"
                                      >
                                        <el-icon
                                          :class="
                                            item.current === 0
                                              ? 'disable'
                                              : 'icon'
                                          "
                                          :size="12"
                                          @click="
                                            handleAnswerChange(item, 'prev')
                                          "
                                        >
                                          <ArrowLeft />
                                        </el-icon>
                                        <span class="subs"
                                          >{{ item.current + 1 }}/{{
                                            item.answer.length
                                          }}</span
                                        >
                                        <el-icon
                                          :class="
                                            item.current ===
                                            item.answer.length - 1
                                              ? 'disable'
                                              : 'icon'
                                          "
                                          :size="12"
                                          @click="
                                            handleAnswerChange(item, 'next')
                                          "
                                        >
                                          <ArrowRight />
                                        </el-icon>
                                      </div>
                                      <el-tooltip
                                        effect="dark"
                                        content="复制"
                                        placement="top"
                                        :show-after="200"
                                      >
                                        <el-icon
                                          class="icon copy-btn"
                                          :size="18"
                                          :data-clipboard-text="
                                            item.answer[item.current].text
                                          "
                                          @click="handleCopy"
                                        >
                                          <CopyDocument />
                                        </el-icon>
                                      </el-tooltip>
                                      <el-tooltip
                                        effect="dark"
                                        content="重新生成"
                                        placement="top"
                                        :show-after="200"
                                      >
                                        <el-icon
                                          v-if="
                                            index ===
                                            answer[robot_chat_id_one].length - 1
                                          "
                                          class="icon"
                                          :size="18"
                                          @click="handleRegenerate(item, 2)"
                                        >
                                          <RefreshLeft />
                                        </el-icon>
                                      </el-tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div>测试2222</div> -->
                          </div>
                          <div
                            class="text item"
                            :ref="'questionref' + index"
                            style="height: inherit"
                            v-if="morechattype == 'chat' && index == 1"
                          >
                            <div
                              v-for="(item, index) in answer[robot_chat_id_two]"
                              :key="index"
                            >
                              <div
                                v-if="item.qus && item.qus != '开启全新对话'"
                                class="qus"
                              >
                                <div style="white-space: pre-wrap">
                                  {{ item.qus }}
                                </div>
                              </div>
                              <div
                                v-if="item.qus && item.qus == '开启全新对话'"
                              >
                                <el-divider>
                                  <span
                                    style="
                                      color: #595757;
                                      white-space: pre-wrap;
                                    "
                                    >{{ item.qus }}</span
                                  >
                                </el-divider>
                              </div>
                              <div
                                v-if="
                                  item.answer.length > 0 && item.answer[0].text
                                "
                                class="answer"
                                style="max-width: 88%"
                              >
                                <img :src="origin + item.logo" alt="" />
                                <div
                                  class="markdown-body"
                                  style="
                                    background-color: #f2f2f2;
                                    padding: 10px;
                                  "
                                >
                                  <div
                                    v-html="item.answer[item.current].text"
                                  ></div>
                                  <div v-if="item.iconShow">
                                    <el-divider
                                      style="margin: 5px 0 8px 0"
                                      border-style="dashed"
                                    />
                                    <div class="icon-box">
                                      <div
                                        v-if="item.answer.length > 1"
                                        class="pagination"
                                      >
                                        <el-icon
                                          :class="
                                            item.current === 0
                                              ? 'disable'
                                              : 'icon'
                                          "
                                          :size="12"
                                          @click="
                                            handleAnswerChange(item, 'prev')
                                          "
                                        >
                                          <ArrowLeft />
                                        </el-icon>
                                        <span class="subs"
                                          >{{ item.current + 1 }}/{{
                                            item.answer.length
                                          }}</span
                                        >
                                        <el-icon
                                          :class="
                                            item.current ===
                                            item.answer.length - 1
                                              ? 'disable'
                                              : 'icon'
                                          "
                                          :size="12"
                                          @click="
                                            handleAnswerChange(item, 'next')
                                          "
                                        >
                                          <ArrowRight />
                                        </el-icon>
                                      </div>
                                      <el-tooltip
                                        effect="dark"
                                        content="复制"
                                        placement="top"
                                        :show-after="200"
                                      >
                                        <el-icon
                                          class="icon copy-btn"
                                          :size="18"
                                          :data-clipboard-text="
                                            item.answer[item.current].text
                                          "
                                          @click="handleCopy"
                                        >
                                          <CopyDocument />
                                        </el-icon>
                                      </el-tooltip>
                                      <el-tooltip
                                        effect="dark"
                                        content="重新生成"
                                        placement="top"
                                        :show-after="200"
                                      >
                                        <el-icon
                                          v-if="
                                            index ===
                                            answer[robot_chat_id_two].length - 1
                                          "
                                          class="icon"
                                          :size="18"
                                          @click="handleRegenerate(item, 3)"
                                        >
                                          <RefreshLeft />
                                        </el-icon>
                                      </el-tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div>测试3333</div> -->
                          </div>
                          <div
                            class="stopbutton"
                            v-if="
                              morechattype == 'chat' && nowsbeck && index == 0
                            "
                            @click="closebutton('more', index)"
                          >
                            <img
                              title="停止回答"
                              class=""
                              style="
                                width: 25px;
                                height: 25px;
                                margin-right: 10px;
                                cursor: pointer;
                              "
                              src="../assets/img/停止回答.png"
                              alt=""
                            />停止回答
                          </div>
                          <div
                            class="stopbutton"
                            v-if="
                              morechattype == 'chat' && nowsbeck2 && index == 1
                            "
                            @click="closebutton('more', index)"
                          >
                            <img
                              title="停止回答"
                              class=""
                              style="
                                width: 25px;
                                height: 25px;
                                margin-right: 10px;
                                cursor: pointer;
                              "
                              src="../assets/img/停止回答.png"
                              alt=""
                            />停止回答
                          </div>
                          <div
                            class="text item"
                            :ref="'questionref' + index"
                            style="height: inherit"
                            v-if="
                              (morechattype == 'robot' ||
                                morechattype == 'chatandrobot') &&
                              index == 1
                            "
                          >
                            <div
                              v-for="(item, index) in answerTemp2"
                              :key="index"
                            >
                              <div
                                v-if="item.qus && item.qus != '开启全新对话'"
                                class="qus"
                              >
                                <div style="white-space: pre-wrap">
                                  {{ item.qus }}
                                </div>
                              </div>
                              <div
                                v-if="item.qus && item.qus == '开启全新对话'"
                              >
                                <el-divider>
                                  <span
                                    style="
                                      color: #595757;
                                      white-space: pre-wrap;
                                    "
                                    >{{ item.qus }}</span
                                  >
                                </el-divider>
                              </div>
                              <div
                                v-if="
                                  item.answer.length > 0 && item.answer[0].text
                                "
                                class="answer"
                                style="max-width: 88%"
                              >
                                <img :src="origin + item.logo" alt="" />
                                <div
                                  class="markdown-body"
                                  style="
                                    background-color: #f2f2f2;
                                    padding: 10px;
                                  "
                                >
                                  <div
                                    v-html="item.answer[item.current].text"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-card>
                      </div>
                    </div>
                  </div>
                  <div class="card_input step12">
                    <div class="newchatshow step13">
                      <img
                        title="取消关联上文"
                        class="newchatimg1"
                        style="width: 30px; margin-right: 10px; cursor: pointer"
                        src="../assets/img/remove1.png"
                        alt=""
                        @click="opennewchat('more')"
                      />
                      <img
                        class="newchatimg"
                        style="width: 30px; margin-right: 10px; cursor: pointer"
                        src="../assets/img/remove.png"
                        alt=""
                      />
                    </div>
                    <div
                      class="card_input_input"
                      style="position: relative; width: 800px; max-width: 800px"
                    >
                      <div
                        class="out_box"
                        :style="
                          isinline
                            ? 'border:2px solid #416BE2;background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                            : 'background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                        "
                        @focusin="isinline = true"
                        @focusout="isinline = false"
                        ref="homefocusin"
                      >
                        <div
                          class="inputs"
                          :style="
                            isinline
                              ? 'border-color:transparent;'
                              : 'border-color:#b2b3b5'
                          "
                        >
                          <textarea
                            v-model="question"
                            @keyup.enter="keydown2"
                            ref="inputText"
                            placeholder="点击“enter”发送，点击“shift+enter”换行"
                            @input="handleInput"
                            rows="2"
                          ></textarea>
                          <!-- <div :class="times.times<=10?'subtimes subtimesactive':'subtimes'">今日剩余提问次数：{{ times.times }}</div>  -->
                          <div class="subbtn" @click="moresendmessage()">
                            <img
                              src="../assets/img/u66.png"
                              style="width: 24px; height: 24px"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 100%" v-if="rightshow == 1">
            <div class="card">
              <div
                class="item_card item_card2"
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                "
              >
                <el-card
                  class="box-card"
                  body-class="box-card-body"
                  :style="
                    robotinfo == true
                      ? 'width:calc(100% - 260px);'
                      : 'width:100%'
                  "
                >
                  <template #header>
                    <div class="card-header">
                      <span @click="robotinfo = true" style="cursor: pointer">
                        {{ infojson.json.name }}</span
                      >
                      <el-button
                        v-if="infojson.json.type != 1 && chattype == 'chat'"
                        type="primary"
                        plain
                        @click="addselect('chat')"
                        class="button step10"
                      >
                        开启对照
                      </el-button>
                      <el-button
                        v-if="infojson.json.type != 1 && chattype == 'robot'"
                        type="primary"
                        plain
                        @click="addselect('robot')"
                        class="button step10"
                      >
                        开启对照
                      </el-button>
                    </div>
                  </template>
                  <div class="card_clum" v-if="chattype == 'robot'">
                    <div class="text item" ref="questionref">
                      <div v-for="(item, index) in answerTemp1" :key="index">
                        <div
                          v-if="item.qus && item.qus != '开启全新对话'"
                          class="qus"
                        >
                          <div style="white-space: pre-wrap">
                            {{ item.qus }}
                          </div>
                        </div>
                        <div v-if="item.qus && item.qus == '开启全新对话'">
                          <el-divider>
                            <span
                              style="color: #595757; white-space: pre-wrap"
                              >{{ item.qus }}</span
                            >
                          </el-divider>
                        </div>
                        <div
                          v-if="item.answer.length > 0 && item.answer[0].text"
                          class="answer"
                          style="max-width: 88%"
                        >
                          <img :src="origin + item.logo" alt="" />
                          <div
                            class="markdown-body"
                            style="background-color: #f2f2f2; padding: 10px"
                          >
                            <div v-html="item.answer[item.current].text"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card_clum" v-if="chattype == 'chat'">
                    <div class="text item" ref="questionref">
                      <p
                        v-if="total > answer[robot_chat_id_one].length"
                        style="
                          text-align: center;
                          cursor: pointer;
                          margin-bottom: 10px;
                          font-size: 14px;
                        "
                        @click="logmore"
                      >
                        加载更多
                      </p>
                      <div
                        v-for="(item, index) in answer[robot_chat_id_one]"
                        :key="index"
                      >
                        <div
                          v-if="item.qus && item.qus != '开启全新对话'"
                          class="qus"
                        >
                          <div style="white-space: pre-wrap">
                            {{ item.qus }}
                          </div>
                        </div>
                        <div v-if="item.qus && item.qus == '开启全新对话'">
                          <el-divider>
                            <span
                              style="color: #595757; white-space: pre-wrap"
                              >{{ item.qus }}</span
                            >
                          </el-divider>
                        </div>
                        <div
                          v-if="item.answer.length > 0 && item.answer[0].text"
                          class="answer"
                        >
                          <img :src="origin + item.logo" alt="" />
                          <div
                            class="markdown-body content-box"
                            style="background-color: #f2f2f2; padding: 10px"
                          >
                            <div v-html="item.answer[item.current].text"></div>
                            <div v-if="item.iconShow">
                              <el-divider
                                style="margin: 5px 0 8px 0"
                                border-style="dashed"
                              />
                              <div class="icon-box">
                                <div
                                  v-if="item.answer.length > 1"
                                  class="pagination"
                                >
                                  <el-icon
                                    :class="
                                      item.current === 0 ? 'disable' : 'icon'
                                    "
                                    :size="12"
                                    @click="handleAnswerChange(item, 'prev')"
                                  >
                                    <ArrowLeft />
                                  </el-icon>
                                  <span class="subs"
                                    >{{ item.current + 1 }}/{{
                                      item.answer.length
                                    }}</span
                                  >
                                  <el-icon
                                    :class="
                                      item.current === item.answer.length - 1
                                        ? 'disable'
                                        : 'icon'
                                    "
                                    :size="12"
                                    @click="handleAnswerChange(item, 'next')"
                                  >
                                    <ArrowRight />
                                  </el-icon>
                                </div>
                                <el-tooltip
                                  effect="dark"
                                  content="复制"
                                  placement="top"
                                  :show-after="200"
                                >
                                  <el-icon
                                    class="icon copy-btn"
                                    :size="18"
                                    :data-clipboard-text="
                                      item.answer[item.current].text
                                    "
                                    @click="handleCopy"
                                  >
                                    <CopyDocument />
                                  </el-icon>
                                </el-tooltip>
                                <el-tooltip
                                  effect="dark"
                                  content="重新生成"
                                  placement="top"
                                  :show-after="200"
                                >
                                  <el-icon
                                    v-if="
                                      index ===
                                      answer[robot_chat_id_one].length - 1
                                    "
                                    class="icon"
                                    :size="18"
                                    @click="handleRegenerate(item, 1)"
                                  >
                                    <RefreshLeft />
                                  </el-icon>
                                </el-tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>测试6666</div> -->
                      </div>
                    </div>
                    <div
                      class="stopbutton"
                      v-if="chattype == 'chat' && nowsbeck"
                      @click="closebutton('none')"
                    >
                      <img
                        title="停止回答"
                        class=""
                        style="
                          width: 25px;
                          height: 25px;
                          margin-right: 10px;
                          cursor: pointer;
                        "
                        src="../assets/img/停止回答.png"
                        alt=""
                      />停止回答
                    </div>
                  </div>
                </el-card>
                <el-card class="robotinfo box-card infocard" v-if="robotinfo">
                  <template #header>
                    <div class="card-header">
                      <span style="height: 32px"> 机器人详情 </span>
                      <!-- <el-button title="关闭" @click="robotinfo == false"
                                                class="button" text> -->
                      <el-icon
                        @click="robotinfo = false"
                        style="cursor: pointer"
                      >
                        <CloseBold />
                      </el-icon>
                      <!-- </el-button> -->
                    </div>
                  </template>
                  <div class="robotinfo_name">
                    <el-avatar :size="50" :src="origin + infojson.json.logo" />
                    <div>{{ infojson.json.name }}</div>
                  </div>
                  <!-- <p class="robotinfo_collect" v-if="infojson.json.is_del == 1">
                    {{ infojson.json.collect_num }}粉丝
                  </p> -->
                  <p class="robotinfo_button" v-if="infojson.json.is_del == 1">
                    <el-button
                      size="small"
                      style="padding: 6px"
                      type="primary"
                      plain
                      @click="tab(selectrobotlist.list[0], 'new-chat')"
                      >新建聊天</el-button
                    >
                    <el-button
                      v-show="infojson.json.type != 1"
                      size="small"
                      style="padding: 6px; width: 88px"
                      v-if="infojson.json.is_collect == false"
                      @click="collectrobot(infojson.json.id, '1')"
                      ><el-icon> <StarFilled /> </el-icon>关注</el-button
                    >
                    <el-button
                      v-show="infojson.json.type != 1"
                      size="small"
                      style="padding: 6px"
                      v-else
                      @click="collectrobot(infojson.json.id, '2')"
                    >
                      <el-icon style="color: yelow; font-size: 18px">
                        <StarFilled /> </el-icon
                      >取消关注</el-button
                    >
                    <el-button
                      v-show="infojson.json.type == 1"
                      size="small"
                      style="padding: 6px"
                      @click="editrobots(infojson.json)"
                    >
                      编辑机器人
                    </el-button>
                    <el-dropdown trigger="click" v-if="infojson.json.type == 1">
                      <el-button
                        size="small"
                        style="padding: 8px; margin-left: 10px"
                        round
                        title="查看提示词"
                      >
                        <el-icon> <MoreFilled /> </el-icon
                      ></el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            @click="lookpamart"
                            v-if="infojson.json.prompt_is_publish == 2"
                          >
                            查看提示词
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="removerobts(infojson.json.id)"
                            >删除机器人</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </p>
                  <p
                    v-if="infojson.json.is_del == 1"
                    style="margin-top: 10px; font-size: 14px; color: #666"
                  >
                    {{ infojson.json.describe }}
                  </p>
                  <p style="margin-top: 10px">
                    <el-button @click="openhistory(infojson.json)"
                      >查看与此机器人的所有聊天</el-button
                    >
                  </p>
                </el-card>
                <div class="card_input" v-if="chattype == 'robot'">
                  <div
                    class="card_input_input"
                    style="width: 800px; max-width: 800px"
                  >
                    <div
                      class="out_box1"
                      v-if="infojson.json.is_del == 2"
                    ></div>
                    <div
                      class="out_box"
                      :style="
                        isinline
                          ? 'border:2px solid #416BE2;background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                          : 'background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                      "
                      @focusin="isinline = true"
                      @focusout="isinline = false"
                    >
                      <div
                        class="inputs"
                        :style="
                          isinline
                            ? 'border-color:transparent;'
                            : 'border-color:#b2b3b5'
                        "
                      >
                        <textarea
                          v-model="question"
                          @keyup.enter="keydown"
                          ref="inputText"
                          :placeholder="
                            infojson.json.is_del == 1
                              ? '点击“enter”发送，点击“shift+enter”换行'
                              : '当前机器人已被删除，无法发送新消息'
                          "
                          @input="handleInput"
                          rows="2"
                        ></textarea>
                        <!-- <div :class="times.times<=10?'subtimes subtimesactive':'subtimes'">今日剩余提问次数：{{ times.times }}</div> -->
                        <div class="subbtn" @click="sendmessage()">
                          <img
                            src="../assets/img/u66.png"
                            style="width: 24px; height: 24px"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card_input" v-if="chattype == 'chat'">
                  <div class="newchatshow">
                    <img
                      title="取消关联上文"
                      class="newchatimg1"
                      style="width: 30px; margin-right: 10px; cursor: pointer"
                      src="../assets/img/remove1.png"
                      alt=""
                      @click="opennewchat('one')"
                      v-show="infojson.json.is_del == 1"
                    />
                    <img
                      class="newchatimg"
                      style="width: 30px; margin-right: 10px; cursor: pointer"
                      src="../assets/img/remove.png"
                      alt=""
                      v-show="infojson.json.is_del == 1"
                    />
                  </div>
                  <div
                    class="card_input_input"
                    style="width: 800px; max-width: 800px"
                  >
                    <div
                      class="out_box1"
                      v-if="infojson.json.is_del !== 1"
                    ></div>
                    <div
                      class="out_box"
                      :style="
                        isinline
                          ? 'border:2px solid #416BE2;background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                          : 'background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                      "
                      @focusin="isinline = true"
                      @focusout="isinline = false"
                    >
                      <div
                        class="inputs"
                        :style="
                          isinline
                            ? 'border-color:transparent;'
                            : 'border-color:#b2b3b5'
                        "
                      >
                        <textarea
                          v-model="question"
                          @keyup.enter="keydown1"
                          ref="inputText"
                          :placeholder="
                            infojson.json.is_del == 1
                              ? '点击“enter”发送，点击“shift+enter”换行'
                              : '当前机器人已被删除，无法发送新消息'
                          "
                          @input="handleInput"
                          rows="2"
                        ></textarea>
                        <!-- <div :class="times.times<=10?'subtimes subtimesactive':'subtimes'">今日剩余提问次数：{{ times.times }}</div> -->
                        <div class="subbtn" @click="chatsendmessage()">
                          <img
                            src="../assets/img/u66.png"
                            style="width: 24px; height: 24px"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%; height: 100%" v-if="rightshow == 0">
            <div class="item_card item_card1" style="width: 100%; height: 100%">
              <div
                style="width: 100%; height: 100%; overflow-y: auto"
                class="step4"
              >
                <div
                  class="card_input_robot step2"
                  style="max-width: 1200px; display: flex; flex-wrap: wrap"
                >
                  <div
                    @click="homeselect(index)"
                    :class="
                      homeactivelist.list[0] == index
                        ? 'active'
                        : homeactivelist.list[1] == index
                        ? 'active1'
                        : ''
                    "
                    v-for="(item, index) in robot.modelslist"
                    :key="index"
                    style="position: relative"
                  >
                    <img :src="origin + item.logo" alt="" />
                    {{ item.name }}
                    <img
                      style="
                        position: absolute;
                        top: -10px;
                        right: -11px;
                        width: 22px;
                      "
                      v-show="mathnowtime(item.create_time)"
                      src="../assets/img/new.png"
                    />
                  </div>
                </div>
                <div
                  class="card_input_input step3"
                  style="margin-left: 10px; width: 1200px; max-width: 1200px"
                >
                  <div
                    class="out_box"
                    :style="
                      isinline
                        ? 'border:2px solid #416BE2;background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                        : 'background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                    "
                    @focusin="isinline = true"
                    @focusout="isinline = false"
                  >
                    <div
                      class="inputs step5"
                      :style="
                        isinline
                          ? 'border-color:transparent;'
                          : 'border-color:none'
                      "
                    >
                      <textarea
                        v-model="question"
                        @keyup.enter="keydown3"
                        ref="inputText"
                        placeholder="在此输入您想了解的内容，输入“/”可查看当前机器人“shift+enter”换行"
                        @input="handleInput1"
                        rows="3"
                      ></textarea>
                      <!-- <div :class="times.times<=10?'subtimes subtimesactive step5':'subtimes step5'">今日剩余提问次数：{{ times.times }}</div> -->
                      <div class="subbtn" @click="sendmessage2()">
                        <img
                          src="../assets/img/u66.png"
                          style="width: 24px; height: 24px"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card_input_input1" style="margin-left: 10px">
                  <div
                    class="card_input_input1_active"
                    v-if="homemaskname != ''"
                  >
                    <span>选择机器人 &nbsp;&nbsp;&nbsp;</span
                    ><img
                      style="
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        margin-right: 10px;
                      "
                      :src="origin + homemasklogo"
                      alt=""
                    />{{ homemaskname }}
                    <div class="close" @click="clearhomeask">
                      <el-icon>
                        <Minus />
                      </el-icon>
                    </div>
                  </div>
                  <div v-else>
                    <span>选择机器人</span>
                  </div>
                </div>

                <div class="card_select step1">
                  <p class="card_select_p">应用场景</p>
                  <div class="card_select_div_tab">
                    <div
                      :class="homemaskactive == index ? 'active' : ''"
                      v-for="(item, index) in robot.maskList"
                      :key="index"
                      @click="showhide(index)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                  <div
                    class="card_select_div"
                    style="margin-top: 0"
                    v-if="robot.maskList"
                  >
                    <el-tooltip
                      effect="light"
                      class="box-item"
                      placement="bottom"
                      v-for="(item, index) in robot.maskList[homemaskactive]
                        .masks"
                      :key="index"
                    >
                      <template #content>
                        <p style="color: #595757; max-width: 540px">
                          {{ item.describe }}
                        </p></template
                      >
                      <div
                        class="card_select_item"
                        :data-id="item.id"
                        data-type="robot"
                        @click="homemaskselect(item)"
                      >
                        <div>
                          <el-avatar :size="60" :src="origin + item.logo" />
                        </div>
                        <p style="text-align: center">{{ item.title }}</p>
                        <p style="font-size: 14px; color: #c4c4c4">
                          {{ item.describe }}
                        </p>
                        <div class="card_select_item_btns">添加为机器人</div>
                      </div>
                    </el-tooltip>
                  </div>
                </div>

                <div class="card_select" v-if="robot.myrobotList.length">
                  <p class="card_select_p">我创建的</p>
                  <div class="card_select_div">
                    <el-tooltip
                      effect="light"
                      class="box-item"
                      placement="bottom"
                      v-for="(item, index) in robot.myrobotList"
                      :key="index"
                    >
                      <template #content>
                        <p style="color: #595757; max-width: 540px">
                          {{ item.describe }}
                        </p></template
                      >
                      <div
                        class="card_select_item"
                        :data-id="item.id"
                        data-type="robot"
                        @click="tab(item, 'create')"
                      >
                        <div>
                          <el-avatar :size="60" :src="origin + item.logo" />
                        </div>
                        <p style="text-align: center">
                          {{ item.name }}
                        </p>
                        <p style="font-size: 14px; color: #c4c4c4">
                          {{ item.describe }}
                        </p>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="container" v-if="!token">
        <div class="item_card" style="width: 100%; height: 100%">
          <el-card
            class="box-card"
            body-class="box-card-body"
            style="
              width: 100%;
              background: transparent;
              border: none;
              box-shadow: none;
              padding-top: 30px;
            "
          >
            <p
              style="
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 30px;
                color: #333;
              "
            >
              智能聊天新体验
            </p>
            <div
              class="card_input_robot"
              style="
                margin: 0 auto;
                width: 1200px;
                display: flex;
                flex-wrap: wrap;
              "
            >
              <div
                v-for="(item, index) in robot.modelslist"
                :key="index"
                :class="homeactive == index ? 'active' : ''"
                @click="sendmessage3()"
              >
                <img :src="origin + item.logo" alt="" />{{ item.name }}
              </div>
            </div>
            <div
              class="card_input_input"
              style="margin: 10px auto; width: 1200px; max-width: 1200px"
            >
              <div
                class="out_box"
                :style="
                  isinline
                    ? 'border:2px solid #416BE2;background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                    : 'background:#fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                "
                @focusin="isinline = true"
                @focusout="isinline = false"
                @click="login()"
              >
                <div class="inputs" style="border: none">
                  <textarea
                    v-model="question"
                    @keyup.enter="keydown2"
                    ref="inputText"
                    placeholder="欢迎来到畅聊"
                    @input="handleInput"
                    rows="3"
                  ></textarea>

                  <div class="subbtn" @click="sendmessage3()">
                    <img
                      src="../assets/img/u66.png"
                      style="width: 24px; height: 24px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card_input_bottom">
              <div class="card_input_bottom_item">
                <img src="../assets/img/u42.png" alt="" />
                <div>
                  <p>多科学模型</p>
                  <p>提供多种信息平台</p>
                </div>
              </div>
              <div class="card_input_bottom_item">
                <img src="../assets/img/u99.png" alt="" />
                <div>
                  <p>双模型对比</p>
                  <p>获取更理想的信息</p>
                </div>
              </div>
              <div class="card_input_bottom_item">
                <img src="../assets/img/u41.png" alt="" />
                <div>
                  <p>预设大模型</p>
                  <p>方便用户快捷提问</p>
                </div>
              </div>
              <div class="card_input_bottom_item">
                <img src="../assets/img/u43.png" alt="" />
                <div>
                  <p>自建机器人</p>
                  <p>创建定制化的方案</p>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </el-container>
      <div class="bottom_info" v-if="!token">
        <div>北京盈科千信科技有限公司</div>
        <div>地址：北京市海淀区中关村南大街48号</div>
        <div>邮箱：product@increscence.net</div>
        <div>
          Copyright © 2024<img
            src="../assets/img/copy_n.png"
            alt=""
          />京ICP备15054044号-1
        </div>
      </div>
    </el-container>
    <el-dialog
      v-model="loginDialog"
      title="个人账号登录"
      width="400px"
      :before-close="handleClose2"
    >
      <div style="width: 70%; margin: 0 auto">
        <el-form>
          <el-form-item>
            <el-input
              ref="usernameinput"
              placeholder="请输入账号/用户名"
              v-model="formLabelAlign.username"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="请输入密码"
              type="password"
              v-model="formLabelAlign.password"
            />
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="loginuser"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
        <p style="text-align: center">
          <span
            style="
              font-size: 12px;
              text-decoration: underline;
              color: #3468f7;
              cursor: pointer;
            "
            @click="showRegisterModal"
            >没有个人账号？点击注册</span
          >
        </p>
      </div>
    </el-dialog>
    <el-dialog
      v-model="registerDialog"
      title="个人账号注册"
      width="500px"
      :before-close="handleClose2"
    >
      <div style="width: 90%; margin: 0 auto">
        <el-form
          ref="registerFormRef"
          :rules="rules"
          label-width="100px"
          :model="registerjson.list"
        >
          <el-form-item label="账号/用户名" prop="username">
            <el-input
              ref="usernameinput"
              placeholder="请输入账号/用户名"
              v-model="registerjson.list.username"
            />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              placeholder="密码"
              type="password"
              v-model="registerjson.list.password"
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="repassword">
            <el-input
              placeholder="确认密码"
              type="password"
              v-model="registerjson.list.repassword"
            />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input placeholder="邮箱" v-model="registerjson.list.email" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input placeholder="手机号" v-model="registerjson.list.phone" />
          </el-form-item>
          <el-form-item label="手机验证码" prop="code">
            <el-input placeholder="手机验证码" v-model="registerjson.list.code">
              <template #append>
                <el-button @click="sendcode" :disabled="isshowtimes">
                  {{ isshowtimes ? timess : "获取验证码" }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <div style="margin: 0 auto">
              <el-button style="" type="primary" @click="registeruser()"
                >注册</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      v-model="myrobotdialog"
      width="600px"
      :before-close="handleClose2"
    >
      <template #header>
        <div class="card-header">
          我的机器人<span>
            {{
              Number(robot.myrobotList.length) +
              Number(robot.maskListcopylength) +
              Number(robot.asideListlength)
            }}
            个</span
          >
        </div>
      </template>
      <div style="overflow-y: auto; height: 65vh" class="myrobot">
        <el-collapse>
          <el-collapse-item name="1">
            <template #title>
              <p style="margin: 10px; font-weight: 600; font-size: 16px">
                我创建的<span>{{ robot.myrobotList.length }}个</span>
              </p>
            </template>

            <div
              class="mychat"
              style="border-bottom: 1px solid #ebeef5"
              v-for="(item, index) in robot.myrobotList"
              :data-id="item.id"
              data-type="robot"
              :key="index"
              @click="tab(item)"
              v-show="item.name"
            >
              <img v-show="item.logo" :src="origin + item.logo" alt="" />
              {{ item.name }}
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-collapse>
          <el-collapse-item name="1">
            <template #title>
              <p style="margin: 10px; font-weight: 600; font-size: 16px">
                应用场景<span>{{ robot.maskListcopylength }}个</span>
              </p>
            </template>
            <el-collapse accordion>
              <el-collapse-item
                :name="item.name"
                v-for="(item, index) in robot.maskListcopy"
                :key="index"
              >
                <template #title>
                  <div style="padding-left: 10px">
                    <p style="margin: 10px; font-weight: 600; font-size: 16px">
                      {{ item.name }}<span>{{ item.num }}个</span>
                    </p>
                  </div>
                </template>
                <div
                  class="mychat"
                  v-for="(items, indexs) in item.masks"
                  :data-id="items.id"
                  :data-name="items.title"
                  data-type="mask"
                  :key="indexs"
                  @click="masktab(items.id)"
                >
                  <img v-show="items.logo" :src="origin + items.logo" alt="" />
                  <div>
                    <p>
                      {{ items.title }} <span>{{ items.num }}个</span>
                    </p>
                    <p>{{ items.describe }}</p>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
        <el-collapse>
          <el-collapse-item name="1">
            <template #title>
              <p style="margin: 10px; font-weight: 600; font-size: 16px">
                基础模型<span>{{ robot.asideListlength }}个</span>
              </p>
            </template>
            <div
              class="mychat"
              style="border-bottom: 1px solid #ebeef5"
              v-for="(item, index) in robot.asideList"
              :data-id="item.id"
              data-type="robot"
              :key="index"
              @click="tab(item)"
              v-show="item.coll?.is_coll"
            >
              <img v-show="item.logo" :src="origin + item.logo" alt="" />
              {{ item.name }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-dialog>
    <el-dialog
      v-model="maskselectdialog"
      title="选择模型"
      width="300"
      :before-close="handleClose2"
    >
      <div class="maskdialog step11">
        <div
          v-for="(item, index) in robot.modelslist"
          :key="index"
          :class="
            selectrobotlist.list[0]?.name.split('-')[0] == item.name
              ? selectrobotlist.list.length == 1
                ? 'gray'
                : ''
              : ''
          "
          @click="
            selectrobotlist.list[0]?.name.split('-')[0] == item.name
              ? selectrobotlist.list.length == 1
                ? ''
                : addmaskmore(item.id)
              : addmaskmore(item.id)
          "
        >
          <el-icon v-if="item.coll?.is_coll">
            <StarFilled />
          </el-icon>
          {{ item.name }}
        </div>
      </div>
    </el-dialog>
    <el-drawer
      v-model="drawer"
      :title="adddialogname"
      :before-close="handleClose"
    >
      <div class="drawertop">
        <el-avatar :size="50" :src="origin + addavatar" @click="uploadimgs" />
        <div @click="uploadimgs">编辑头像</div>
      </div>
      <div class="drawerform">
        <h4>机器人名称</h4>
        <!-- <p>请输入4-20个字符，可包含数字、字幕和特殊字符</p> -->
        <el-input
          v-model="addrotname"
          placeholder="请输入4-20个字符，可包含数字、字幕和特殊字符"
        />
      </div>
      <div class="drawerform">
        <h4>选择大模型</h4>
        <el-select
          v-model="addrotmodel"
          placeholder="选择大模型"
          style="width: 100%"
        >
          <el-option
            v-for="item in robot.modelslist"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <!-- <h3>机器人行为</h3> -->
      <div class="drawerform">
        <h4>提示词</h4>
        <p>告诉你的机器人如何表现以及如何回应用户的消息。尽量说得具体些</p>
        <el-input
          v-model="addrotprompt"
          type="textarea"
          placeholder="例如：你是烘焙师，你会以辛辣的方式回复每一条用户信息，不要在的回答中使用任何脏话或者粗俗的词语。"
          rows="5"
        />
      </div>
      <div class="drawerform">
        <div>
          <span>在机器人简介中显示提示词</span
          ><el-switch v-model="addrotswitch" />
        </div>
      </div>
      <div class="drawerform">
        <h4>问候语</h4>
        <p>机器人将在每次对话开始时发送消息</p>
        <el-input
          v-model="addrotgreeting"
          type="textarea"
          placeholder="例如：您好！我是智能图书馆员！"
          rows="5"
        />
      </div>
      <div class="drawerform">
        <h4>机器人描述</h4>
        <p>可以简要描述机器人涉及的领域、主要功能及用途</p>
        <el-input
          v-model="adddescribe"
          type="textarea"
          placeholder="例如：您好！我是智能图书馆员！"
          rows="5"
        />
      </div>
      <div>
        <el-button style="width: 100%" type="primary" @click="createRobot">{{
          adddialogname
        }}</el-button>
      </div>
    </el-drawer>
    <el-dialog
      v-model="lookpamartdialog"
      width="30%"
      :before-close="handleClose2"
    >
      <div class="promptdiv">
        <img
          style="margin: 5px auto; text-align: center; border-radius: 50%"
          :src="origin + infojson.json.logo"
          alt=""
        />
        <div
          style="
            margin: 10px auto;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
          "
        >
          {{ infojson.json.name }}
        </div>
        <div
          style="
            margin: 20px auto;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
          "
        >
          {{ infojson.json.prompt }}
        </div>
      </div>
    </el-dialog>
    <div class="select-dialog">
      <el-dialog
        v-model="openselectdialog"
        title="Tips"
        width="1200px"
        :before-close="handleCloseopen"
        :show-close="false"
      >
        <template #header>
          <div class="select-header">
            <div class="select-header-div">
              <span style="height: 32px"> 选择机器人 </span>
              <!-- <el-icon @click="openselectdialog = false" class="select-header-close" style="cursor: pointer">
                <CloseBold />
              </el-icon> -->
              <img
                @click="openselectdialog = false"
                src="../assets/img/close.png"
                alt=""
                class="select-header-close"
                style="cursor: pointer"
              />
            </div>
            <div class="select-header-center">
              <div>
                <div
                  :class="myrose == 1 ? 'divactive' : ''"
                  @click="myrose = 1"
                >
                  我创建的
                </div>
                <div
                  :class="myrose == 2 ? 'divactive' : ''"
                  @click="myrose = 2"
                >
                  应用场景
                </div>
              </div>
              <div class="select-header-input step8">
                <div style="margin: 0 0 0 10px">
                  <img src="../assets/img/search.png" alt="" />
                </div>
                <el-input
                  style="margin: 0 10px 0 0"
                  v-model="robotsearch"
                  clearable
                  @clear="robotsearchsub"
                  @keyup.enter="robotsearchsub"
                  placeholder="输入机器人名称"
                />
              </div>
            </div>
          </div>
        </template>
        <div>
          <div v-if="myrose == 1" class="searchdialog">
            <div
              class="card_select_div_tab"
              v-if="searchrobotlist.myrobotlist.length > 0"
            >
              <el-select v-model="robotselect" @change="getCouponSelected">
                <el-option label="访问量" value="visits"></el-option>
                <el-option label="时间正序" value="create_time"></el-option>
                <el-option label="时间倒叙" value="-create_time"></el-option>
              </el-select>
            </div>
            <div class="card_select_div">
              <div
                class="card_select_item"
                @click="totab(item)"
                v-for="(item, index) in searchrobotlist.myrobotlist"
                :key="index"
                :data-id="item.id"
                data-type="robot"
              >
                <div>
                  <el-avatar :size="60" :src="origin + item.logo" />
                </div>
                <p style="text-align: center">{{ item.name }}</p>
                <p style="font-size: 14px; color: #c4c4c4">
                  {{ item.describe }}
                </p>
                <div class="card_select_item_text">
                  <div>
                    <img src="../assets/img/eye.png" alt="" />{{ item.visits }}
                  </div>
                  <div>
                    <img src="../assets/img/time.png" alt="" />{{
                      item.create_time.split(" ")[0]
                    }}
                  </div>
                </div>
              </div>
              <div
                class="card_select_quesheng"
                v-if="searchrobotlist.myrobotlist.length == 0"
              >
                <img src="../assets/img/quesheng.png" alt="" />
                <p>没有找到对应机器人</p>
              </div>
            </div>
          </div>
          <div v-if="myrose == 2" class="searchdialog">
            <div
              class="card_select_div_tab step6"
              v-if="searchrobotlist.alllist.length > 0"
            >
              <div
                class="card_select_div_item"
                @click="showhidedia('all')"
                :class="homemaskactivedialog == 'all' ? 'active' : ''"
              >
                全部
              </div>
              <div
                v-show="item.masks.length > 0"
                class="card_select_div_item"
                :class="Number(homemaskactivedialog) == index ? 'active' : ''"
                v-for="(item, index) in searchrobotlist.masklist"
                :key="index"
                @click="showhidedia(index)"
              >
                {{ item.name }}
              </div>
              <el-select
                class="step7"
                v-model="robotselect"
                @change="getCouponSelected"
              >
                <el-option label="访问量" value="visits"></el-option>
                <el-option label="时间正序" value="create_time"></el-option>
                <el-option label="时间倒叙" value="-create_time"></el-option>
              </el-select>
            </div>
            <div
              class="card_select_div"
              style="margin-top: 0; flex-wrap: wrap; max-height: 57vh"
              v-if="searchrobotlist.masklist && homemaskactivedialog != 'all'"
            >
              <div
                class="card_select_item"
                style="margin-bottom: 10px"
                v-for="(item, index) in searchrobotlist.masklist[
                  homemaskactivedialog
                ].masks"
                :key="index"
                :data-id="item.id"
                data-type="robot"
              >
                <div>
                  <el-avatar :size="60" :src="origin + item.logo" />
                </div>
                <p style="text-align: center">{{ item.title }}</p>
                <p style="font-size: 14px; color: #c4c4c4">
                  {{ item.describe }}
                </p>
                <div class="card_select_item_text">
                  <div>
                    <img src="../assets/img/eye.png" alt="" />{{ item.visits }}
                  </div>
                  <div>
                    <img src="../assets/img/time.png" alt="" />{{
                      item.create_time.split(" ")[0]
                    }}
                  </div>
                </div>
                <div
                  class="card_select_item_btns"
                  @click="homemaskselect(item)"
                >
                  添加为机器人
                </div>
              </div>
              <div
                class="card_select_quesheng"
                v-if="
                  searchrobotlist.masklist[homemaskactivedialog].masks.length ==
                  0
                "
              >
                <img src="../assets/img/quesheng.png" alt="" />
                <p>没有找到对应机器人</p>
              </div>
            </div>
            <div
              class="card_select_div step9"
              style="margin-top: 0; flex-wrap: wrap; max-height: 57vh"
              v-if="searchrobotlist.alllist && homemaskactivedialog == 'all'"
            >
              <div
                class="card_select_item"
                style="margin-bottom: 10px"
                v-for="(item, index) in searchrobotlist.alllist"
                :key="index"
                :data-id="item.id"
                data-type="robot"
              >
                <div>
                  <el-avatar :size="60" :src="origin + item.logo" />
                </div>
                <p style="text-align: center">{{ item.title }}</p>
                <p style="font-size: 14px; color: #c4c4c4">
                  {{ item.describe }}
                </p>
                <div class="card_select_item_text">
                  <div>
                    <img src="../assets/img/eye.png" alt="" />{{ item.visits }}
                  </div>
                  <div>
                    <img src="../assets/img/time.png" alt="" />{{
                      item.create_time.split(" ")[0]
                    }}
                  </div>
                </div>
                <div
                  class="card_select_item_btns"
                  @click="homemaskselect(item)"
                >
                  添加为机器人
                </div>
              </div>
              <div
                class="card_select_quesheng"
                v-if="searchrobotlist.alllist.length == 0"
              >
                <img src="../assets/img/quesheng.png" alt="" />
                <p>没有找到对应机器人</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <template #footer>
          <span class="dialog-footer">
            <el-button @click="openselectdialog = false">Cancel</el-button>
            <el-button type="primary" @click="openselectdialog = false">
              Confirm
            </el-button>
          </span>
        </template> -->
      </el-dialog>
    </div>
    <div class="select-dialog">
      <el-dialog
        v-model="versiondialog"
        title="Tips"
        width="1200px"
        :before-close="handleCloseopen"
        :show-close="false"
      >
        <template #header>
          <div class="select-header">
            <div class="select-header-div">
              <span style="height: 32px"> 更新通知 </span>
              <!-- <el-icon @click="openselectdialog = false" class="select-header-close" style="cursor: pointer">
                <CloseBold />
              </el-icon> -->
              <img
                @click="versiondialog = false"
                src="../assets/img/close.png"
                alt=""
                class="select-header-close"
                style="cursor: pointer"
              />
            </div>
          </div>
        </template>
        <div>
          <el-card style="max-height: 60vh; overflow: auto">
            <div class="titleandtime">
              <span class="vtitle">{{ version.list.title }}</span>
              <span class="ttitle"
                >更新时间：{{ version.list.online_time }}</span
              >
            </div>
            <div class="titleandtime">
              <div v-html="version.list.content"></div>
            </div>
          </el-card>
        </div>
        <!-- <template #footer>
          <span class="dialog-footer">
            <el-button @click="openselectdialog = false">Cancel</el-button>
            <el-button type="primary" @click="openselectdialog = false">
              Confirm
            </el-button>
          </span>
        </template> -->
      </el-dialog>
      <el-dialog
        class="register-guide-dialog"
        v-model="registerGuideModal"
        title=""
        width="400px"
      >
        <div class="register-container">
          <p class="register-tips">注册教程</p>
          <p class="register-guide-text">
            ①点击注册后，请先进行机构账号登录以验证您的机构身份（如未出现此界面，请忽略此步骤）
          </p>
          <el-image
            style="width: 100%"
            :src="require('@/assets/img/register_1.png')"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="[require('@/assets/img/register_1.png')]"
            :initial-index="4"
            fit="cover"
          />
          <p class="register-guide-text">②点击注册，输入对应个人信息进行注册</p>
          <el-image
            style="width: 100%"
            :src="require('@/assets/img/register_2.png')"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="[require('@/assets/img/register_2.png')]"
            :initial-index="4"
            fit="cover"
          />
          <p class="register-guide-text">
            ③注册成功后，点击登录，输入账号密码后即可使用畅聊
          </p>
          <el-image
            style="width: 100%"
            :src="require('@/assets/img/register_3.png')"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="[require('@/assets/img/register_3.png')]"
            :initial-index="4"
            fit="cover"
          />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import Clipboard from "clipboard";
// import 'github-markdown-css/github-markdown-dark.css'
// 引入driver逻辑
import { driver } from "driver.js";
// 引入driver样式
import "driver.js/dist/driver.css";
import "github-markdown-css/github-markdown.css";
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  watch,
  shallowRef,
  computed,
} from "vue";
import { marked } from "marked"; //引入markdown
import hljs from "highlight.js"; //引入代码高亮文件
import "highlight.js/styles/github.css"; //样式文件
import {
  CloseBold,
  Plus,
  Minus,
  SwitchFilled,
  Delete,
  StarFilled,
  MoreFilled,
  ArrowRight,
  CopyDocument,
  RefreshLeft,
  ArrowLeft,
} from "@element-plus/icons-vue"; //引入element-plus的图标
import { closeWebSocket, initWebSocket } from "../utils/websocket"; //引入websocket
import { closeWebSocket2, initWebSocket2 } from "../utils/websocket2"; //引入websocket
import {
  getChannel, //获取频道
  robotqs,
  heartbeat, // 心跳
  getrobotlist,
  getmodelslist,
  addrobot,
  getmsghistory,
  getrobotinfo,
  delrobot,
  editrobot,
  uploadimg,
  createchat,
  getchatlist,
  collect,
  delchat,
  getqatimes,
  getmasklist,
  logins,
  clearhistory,
  registerapi,
  sendmsg,
  iplogin,
  stopreply, // 停止回答
  getchangelog, // 获取变更记录
} from "../api/index"; //引入接口
import store from "../store";
import { ElMessage, ElPopconfirm, FormInstance } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { getAssetsPath } from "../utils/util";
const render = new marked.Renderer();
let mOptions = {
  renderer: render, // 这是必填项
  gfm: true, // 启动类似于Github样式的Markdown语法
  pedantic: false, // 只解析符合Markdwon定义的，不修正Markdown的错误
  // 高亮的语法规范
  highlight: (code: any, lang: any) => {
    console.log(lang);
    if (
      typeof lang === "undefined" ||
      lang === "vue" ||
      lang === "html<template><div>"
    ) {
      return hljs.highlight(code, { language: "javascript" }).value;
    }
    return hljs.highlight(code, { language: lang ? lang : "javascript" }).value;
  },
};
marked.setOptions(mOptions);

interface Params {
  query: string;
  channel_id: number | string | null;
  robot_chat_id: number | string;
  history: boolean;
  msg_id?: null | number;
}
export default defineComponent({
  name: "HomeView",
  components: {
    CloseBold,
    Plus,
    SwitchFilled,
    Minus,
    StarFilled,
    MoreFilled,
    Delete,
    ArrowRight,
    CopyDocument,
    RefreshLeft,
    ArrowLeft,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const registerFormRef = ref<FormInstance>();
    var retokens: any = ref("");
    const emailValidator = (rule: any, value: any, callback: any) => {
      const reEmail =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!value) {
        callback(new Error("邮箱不可为空"));
      } else if (reEmail.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的邮箱格式"));
      }
    };
    const rules: any = reactive({
      username: [
        { required: true, message: "请输入账号/用户名", trigger: "blur" },
      ],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      repassword: [
        { required: true, message: "请再次输入密码", trigger: "blur" },
      ],
      email: [
        {
          required: true,
          trigger: "blur",
          validator: emailValidator,
        },
      ],
      phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      code: [{ required: true, message: "请输入手机验证码", trigger: "blur" }],
    });
    var nowsbeck = ref(false);
    var nowsbeck2 = ref(false);
    // var origin:any = ref(window.location.origin + '/');
    var origin: any = ref("https://chatlibrary.newacademic.net/");
    var token: any = ref(localStorage.getItem("token"));
    var loginDialog = ref(false);
    var registerDialog = ref(false);
    var homeactive = ref(0);
    var username: any = ref(localStorage.getItem("userName"));
    var userRole: any = ref({});
    if (localStorage.getItem("role")) {
      userRole.value = JSON.parse(localStorage.getItem("role") || "");
    }
    var registerjson: any = reactive({
      list: {
        username: "",
        password: "",
        repassword: "",
        email: "",
        phone: "",
        code: "",
        organ_code: "",
      },
    });
    var formLabelAlign: any = reactive({
      username: "",
      password: "",
    });
    var homefocusin = ref<any>(null);
    var usernameinput = ref<any>(null);
    var maskrobotListdialog = ref(false);
    var robotlistpage = ref(1);
    var robot: any = reactive({
      asideList: [],
      modelslist: [],
      chatList: [],
      myrobotList: [],
    });
    var openselectdialog = ref(false);
    var chatpage = ref(1);
    var chattotal = ref(0);
    var maskpage = ref(1);
    var homeactivelist: any = reactive({
      list: [0],
    });
    var homemaskactive = ref(0);
    var homemaskactivedialog = ref("all");
    var homeaskindex = ref(0);
    var homemaskid: any = ref("");
    var homemaskname = ref("");
    var homemasklogo = ref("");
    var isinline = ref(false);
    var question = ref("");
    var robot_chat_id_one = ref("");
    var robot_chat_id_two = ref("");
    var robotinfo: any = ref(true);
    var answer: any = reactive({});
    var selectrobotlist: any = reactive({
      list: [],
    });
    var rightshow = ref(0);
    var infojson: any = reactive({
      json: {},
    });
    var heartbeatInterval: any = null;
    var isHeartbeatRunning = false;
    var wsurl = "";
    var morelchannel_id: any = reactive({
      channel_id1: "",
      channel_id2: "",
      heartbeatInterval1: null,
      heartbeatInterval2: null,
      isHeartbeatRunning1: false,
      isHeartbeatRunning2: false,
    });
    var myrose = ref(1);
    var ishistory = ref(true);
    var chattype = ref("robot");
    var mask_id: any = ref("");
    var maskselectdialog = ref(false);
    var myrobotdialog = ref(false);
    var questionref0 = ref<any>(null);
    var questionref = ref<any>(null);
    var questionref1 = ref<any>(null);
    var morechattype: any = ref("");
    var drawer = ref(false);
    var adddialogname = ref("创建机器人");
    var addrotname = ref("");
    var addrotprompt = ref("");
    var addrotmodel = ref("");
    var addrotgreeting = ref("");
    var adddescribe = ref("");
    var addrotswitch = ref(false);
    var addavatar = ref("");
    var addid = ref("");
    var lookpamartdialog = ref(false);
    var historyheight = ref<any>(null);
    var times: any = ref(0);
    interface Historyshowselect {
      list: {
        name?: any;
      };
    }
    var historyshowselect: Historyshowselect = reactive({
      list: {},
    });
    var historyselecttrue = ref(false);
    var chatpageloading = ref(false);
    var page = ref(1);
    var total = ref(0);
    var historyloading = ref(false);
    var robotsearch = ref("");
    var robotselect = ref("visits");
    var searchrobotlist: any = reactive({
      // myrobotlist: [],
      // masklist: [],
    });
    var versiondialog = ref(false);
    var version = reactive({
      list: [],
    });
    var timess = ref(60);
    var isshowtimes = ref(false);
    var registerGuideModal = ref(false);
    const mathnowtime = (time: string) => {
      // 同过当前时间戳和传入时间yyyy-mm-dd 计算时间差，如果当前时间大于传入时间一周则返回false 否则返回true
      var now = new Date().getTime();
      var date = new Date(time).getTime();
      if (now - date > 604800000) {
        return false;
      } else {
        return true;
      }
    };
    const productCards = [
      {
        role: "ChatModels",
        title_cn: "多模型语言互动平台",
        title_en: "ChatModels",
        image_url: getAssetsPath("product-card-1.png"),
        hover_color:
          "linear-gradient(136.47deg, rgba(36,63,161,1) 2.6%,rgba(36,84,255,1) 99.97%)",
      },
      {
        role: "ChatAcademic",
        title_cn: "智能学术写作平台",
        title_en: "ChatAcademic",
        image_url: getAssetsPath("product-card-3.png"),
        hover_color:
          " linear-gradient(134.1deg, rgba(215,101,139,1) 1.87%,rgba(254,199,141,1) 98.38%)",
        path: "https://chatlibrary.newacademic.net/writingAIHome",
      },
      {
        role: "ChatPPT",
        title_cn: "智能演示文稿生成平台",
        title_en: "MagicPPT",
        image_url: getAssetsPath("product-card-2.png"),
        hover_color:
          "linear-gradient(135.53deg, rgba(49,21,122,1) -1.27%,rgba(140,35,251,1) 100.99%)",
        path: "https://magicppt.newacademic.net/#/",
      },
    ];
    const sendcode = () => {
      if (registerjson.list.phone == "") {
        ElMessage.error("请输入手机号");
        return;
      }
      sendmsg({
        phone: registerjson.list.phone,
      }).then((res: any) => {
        if (res.data.code == 0) {
          ElMessage.success("发送成功");
          isshowtimes.value = true;
          var timer = setInterval(() => {
            timess.value--;
            if (timess.value == 0) {
              clearInterval(timer);
              isshowtimes.value = false;
              timess.value = 60;
            }
          }, 1000);
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    const getstep1 = () => {
      // driver配置对象
      const driverObj = driver({
        // 显示步骤进度
        showProgress: true,
        // 不允许其它方式关闭
        allowClose: true,
        nextBtnText: "下一步",
        prevBtnText: "上一步",
        doneBtnText: "完成",
        steps: [
          {
            element: ".step1",
            popover: {
              title: "开始聊天",
              description:
                "与各种先进的AI模型进行对话，可以通过 预设机器人快捷得到您想要的答案",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step2",
            popover: {
              title: "选择机器人",
              description: "可选择对应机器人，进行专业提问",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step3",
            popover: {
              title: "选择机器人",
              description: "输入框只输入“/”情况时，可精确检索机器人",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step4",
            popover: {
              title: "选择AI模型",
              description:
                "可选对应模型进行提问，AI模型最多可选择2个，您可以根据两个模型的不同结论，选择心仪的答案.",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step5",
            popover: {
              title: "输入问题",
              description:
                "输入问题后，如当日剩余足够的提问次数。点击对应按钮或敲击回车键，即可进行提问",
              align: "start",
              side: "left",
            },
          },
        ],
      });
      driverObj.drive();
    };
    const getstep2 = () => {
      // driver配置对象
      const driverObj1 = driver({
        // 显示步骤进度
        showProgress: true,
        // 不允许其它方式关闭
        allowClose: true,
        nextBtnText: "下一步",
        prevBtnText: "上一步",
        doneBtnText: "完成",
        steps: [
          {
            element: ".step6",
            popover: {
              title: "选择类型",
              description: "可查看当前不同类型的机器人清单",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step7",
            popover: {
              title: "选择排序",
              description: "可对当前标签下机器人进行对应规则的排序",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step8",
            popover: {
              title: "名称搜索",
              description: "框内输入机器人名称，进行检索",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step9",
            popover: {
              title: "选择机器人",
              description: "根据对应信息，添加为对应机器人",
              align: "start",
              side: "left",
            },
          },
        ],
      });
      driverObj1.drive();
    };
    const getstep3 = () => {
      // driver配置对象
      const driverObj2 = driver({
        // 显示步骤进度
        showProgress: true,
        // 不允许其它方式关闭
        allowClose: true,
        nextBtnText: "下一步",
        prevBtnText: "上一步",
        doneBtnText: "完成",
        steps: [
          {
            element: ".step10",
            popover: {
              title: "开启对照模式",
              description:
                "当机器人为预设机器人或基础模型机器人时，可开启对照模式",
              align: "start",
              side: "left",
              onNextClick: () => {
                if (chattype.value == "robot" && infojson.json.type != 1) {
                  addselect("robot");
                } else {
                  addselect("chat");
                }
                setTimeout(() => {
                  driverObj2.moveNext();
                }, 100);
              },
            },
          },
          {
            element: ".step11",
            popover: {
              title: "选择模型",
              description: "可选择任一不同的模型进行对比提问",
              align: "start",
              side: "left",
              onNextClick: () => {
                var arr = [1, 2, 3, 4];
                var index = arr.indexOf(infojson.json.robot_id);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                var random = Math.floor(Math.random() * arr.length);
                addmaskmore(arr[random]);
                setTimeout(() => {
                  driverObj2.moveNext();
                }, 100);
              },
            },
          },
          {
            element: ".step12",
            popover: {
              title: "输入问题",
              description: "输入问题后，即可进行提问",
              align: "start",
              side: "left",
            },
          },
          {
            element: ".step13",
            popover: {
              title: "清除关联",
              description:
                "点击此处，接下来的提问会取消与上文的关联执行全新的对话。",
              align: "start",
              side: "left",
            },
          },
        ],
      });
      driverObj2.drive();
    };
    const getCouponSelected = (e: any) => {
      robotsearchsub();
    };
    const robotsearchsub = () => {
      getrobotlist({
        page: 1,
        size: 10,
        type: 1,
        robotname: robotsearch.value,
        sortfiled: robotselect.value,
      }).then((res) => {
        searchrobotlist.myrobotlist = res.data.data.data;
      });
      getmasklist({
        page: 1,
        size: 10,
        robotname: robotsearch.value,
        sortfiled: robotselect.value,
        show_type: 1,
      }).then((res) => {
        searchrobotlist.masklist = res.data.data.data;
        // searchrobotlist.alllist = []
        // res.data.data.data.map((item:any) => {
        //   searchrobotlist.alllist= searchrobotlist.alllist.concat(item.masks)
        // })
      });
      getmasklist({
        page: 1,
        size: 10,
        robotname: robotsearch.value,
        sortfiled: robotselect.value,
        show_type: 2,
      }).then((res) => {
        searchrobotlist.alllist = res.data.data.data;
      });
    };
    const opennewchat = (e: any) => {
      if (e == "more") {
        if (
          answer[robot_chat_id_one.value][
            answer[robot_chat_id_one.value].length - 1
          ].qus != "开启全新对话"
        ) {
          answer[robot_chat_id_one.value].push({
            qus: "开启全新对话",
            answer: "",
            logo: selectrobotlist.list[0].logo,
          });
        }
        clearhistory({
          chat_id: robot_chat_id_one.value,
        }).then((res) => {});
        if (
          answer[robot_chat_id_two.value][
            answer[robot_chat_id_two.value].length - 1
          ].qus != "开启全新对话"
        ) {
          answer[robot_chat_id_two.value].push({
            qus: "开启全新对话",
            answer: "",
            logo: selectrobotlist.list[1].logo,
          });
        }
        clearhistory({
          chat_id: robot_chat_id_two.value,
        }).then((res) => {});
        autoScroll("questionref0");
        autoScroll("questionref1");
      } else {
        if (
          answer[robot_chat_id_one.value][
            answer[robot_chat_id_one.value].length - 1
          ].qus != "开启全新对话"
        ) {
          answer[robot_chat_id_one.value].push({
            qus: "开启全新对话",
            answer: "",
            logo: selectrobotlist.list[0].logo,
          });
        }
        clearhistory({
          chat_id: robot_chat_id_one.value,
        }).then((res) => {});
        autoScroll("questionref");
      }
      ishistory.value = false;
    };
    const getqatime = () => {
      if (localStorage.getItem("token")) {
        getqatimes().then((res) => {
          times.value = res.data.data;
        });
      }
    };
    const focusin = () => {
      isinline.value = true;

      setTimeout(() => {
        usernameinput.value.focus();
      }, 100);
      sendmessage3();
    };
    const logmore = () => {
      if (total.value > page.value * 10) {
        page.value++;
        gethistory();
      } else {
        ElMessage.error("没有更多了");
      }
    };
    const gethistory = () => {
      if (historyloading.value == false) {
        historyloading.value = true;
        var json = {
          page: page.value,
          size: 10,
          robot_chat_id: robot_chat_id_one.value,
        };
        getmsghistory(json).then((res) => {
          var data = res.data.data.data;
          var answers: any = [];
          total.value = res.data.data.total;
          // data 倒叙
          data.reverse();
          data.forEach((item: any, index: number) => {
            item.answer.reverse();
            item.answer.forEach((v: any) => {
              v.text = marked(v.text);
            });
            answers.push({
              qus: item.question,
              answer: item.answer,
              logo: item.logo || selectrobotlist.list[0].logo,
              iconShow: true,
              current: 0,
            });
          });
          if (!answer[robot_chat_id_one.value]) {
            answer[robot_chat_id_one.value] = answers;
          } else {
            answer[robot_chat_id_one.value] = answers.concat(
              answer[robot_chat_id_one.value]
            );
          }
          if (page.value == 1) {
            autoScroll("questionref");
          }

          historyloading.value = false;
        });
      }
    };
    const openmyrobotcard = () => {
      var arr: any = [];
      robot.maskrobotList.forEach((item: any, index: any) => {
        if (item.coll.is_coll == true) {
          arr.push(item);
        }
      });
      var maskList = JSON.parse(JSON.stringify(robot.maskList));
      arr.forEach((item: any, index: any) => {
        maskList.forEach((items: any, indexs: any) => {
          items.masks.forEach((itemss: any, indexss: any) => {
            if (item.name.split("-")[1] == itemss.title) {
              if (itemss.num) {
                itemss.num = itemss.num + 1;
              } else {
                itemss.num = 1;
              }
              if (items.num) {
                items.num = items.num + 1;
              } else {
                items.num = 1;
              }
            }
          });
        });
      });
      for (let i = maskList.length - 1; i >= 0; i--) {
        if (!maskList[i].num) {
          maskList.splice(i, 1);
        } else {
          for (let j = maskList[i].masks.length - 1; j >= 0; j--) {
            if (!maskList[i].masks[j].num) {
              maskList[i].masks.splice(j, 1);
            }
          }
        }
      }
      var length = 0;
      robot.asideList.forEach((item: any, index: any) => {
        if (item.coll.is_coll == true) {
          length += 1;
        }
      });
      robot.asideListlength = length;
      robot.maskListcopy = maskList;
      robot.maskListcopylength = arr.length;
      myrobotdialog.value = true;
    };
    const delchats = (id: any, index: number, targetArr: any[]) => {
      delchat({
        robot_chat_id: id,
      }).then((res) => {
        if (res.data.code == 0) {
          chatpage.value = 1;
          // getchatlists("none");
          targetArr.splice(index, 1);
          ElMessage.success("删除成功");
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    const historyselectclick = (e: any) => {
      if (e == "all") {
        historyshowselect.list = {};
        historyselecttrue.value = false;
        historychat("chat");
      } else {
        openhistory(e);
      }
    };
    const openDrawer = () => {
      drawer.value = true;
      adddialogname.value = "创建机器人";
    };
    const openhistory = (e: any) => {
      getchatlist({
        page: chatpage.value,
        size: 20,
        robot_id: e.id,
      }).then((res) => {
        robot.chatListse = res.data.data.data;
        chattotal.value = res.data.data.total;
        historyshowselect.list = e;
        historyselecttrue.value = true;
        historychat("none");
      });
    };
    const historychat = (e: any) => {
      rightshow.value = 3;
      if (e == "chat") {
        historyshowselect.list = {};
        historyselecttrue.value = false;
        chatpage.value = 1;
        getchatlists("none");
      } else {
        chatpage.value = 1;
        getchatlists("none");
      }
      setTimeout(() => {
        historyheight.value.onscroll = () => {
          if (
            historyheight.value.scrollTop + historyheight.value.clientHeight ==
            historyheight.value.scrollHeight
          ) {
            // 如果chattotal 大于 chatpage * 20 说明还有数据
            if (chattotal.value > chatpage.value * 20) {
              chatpage.value = chatpage.value + 1;
              cancatchatlist();
            } else {
              ElMessage.error("没有更多数据了");
            }
          }
        };
      }, 100);
    };
    const cancatchatlist = () => {
      if (chatpageloading.value == true) {
        return;
      }
      chatpageloading.value = true;
      getchatlist({
        page: chatpage.value,
        size: 20,
      }).then((res) => {
        if (res.data.code == 0) {
          chatpageloading.value = false;
          robot.chatList = robot.chatList.concat(res.data.data.data);
          chattotal.value = res.data.data.total;
        } else {
          ElMessage.error("获取失败");
        }
      });
    };
    // 查看提示词
    const lookpamart = () => {
      lookpamartdialog.value = true;
    };
    const uploadimgs = () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (e: any) => {
        const file = e.target.files[0];
        var data = new FormData();
        data.append("file", file);
        uploadimg(data).then((res) => {
          addavatar.value = res.data.data.path;
        });
      };
      input.click();
    };
    const editrobots = (e: any) => {
      addid.value = e.id;
      adddialogname.value = "编辑机器人";
      addrotname.value = e.name;
      addrotprompt.value = e.prompt;
      addrotmodel.value = e.robot_id;
      addrotgreeting.value = e.greetings;
      if (e.prompt_is_publish == 2) {
        addrotswitch.value = true;
      } else {
        addrotswitch.value = false;
      }
      addavatar.value = e.logo;
      adddescribe.value = e.describe;
      drawer.value = true;
    };
    const createRobot = () => {
      if (!addrotname.value) {
        ElMessage.error("请输入机器人名称");
        return;
      }
      if (!addrotprompt.value) {
        ElMessage.error("请输入提示词");
        return;
      }
      if (!addrotmodel.value) {
        ElMessage.error("请选择基本机器人");
        return;
      }
      var addrotswitchs: any = "";
      if (addrotswitch.value == false) {
        addrotswitchs = "1";
      } else {
        addrotswitchs = "2";
      }
      if (adddialogname.value == "创建机器人") {
        var data: any = {
          name: addrotname.value,
          prompt: addrotprompt.value,
          robot_id: addrotmodel.value,
          greetings: addrotgreeting.value,
          messages: [],
          logo: addavatar.value,
          prompt_is_publish: addrotswitchs,
          describe: adddescribe.value,
        };
        if (addavatar.value == "") {
          data = {
            name: addrotname.value,
            prompt: addrotprompt.value,
            robot_id: addrotmodel.value,
            greetings: addrotgreeting.value,
            messages: [],
            describe: adddescribe.value,
            prompt_is_publish: addrotswitchs,
          };
        }
        addrobot(data).then((res) => {
          if (res.data.code == 0) {
            drawer.value = false;
            ElMessage.success("创建成功");
            addrotname.value = "";
            addrotprompt.value = "";
            addrotmodel.value = "";
            addrotgreeting.value = "";
            adddescribe.value = "";
            addavatar.value = "";
            getrobotlist({
              page: 1,
              size: 2000,
              type: "1",
            }).then((res) => {
              if (res.data.code == 0) {
                robot.myrobotList = res.data.data.data;
                robot.myrobotList.forEach((item: any, index: any) => {
                  if (item.id == res.data.data.robot_id) {
                    tab(item);
                  }
                });
              } else {
                console.log(res);
              }
            });
            // 收藏
            collectrobot(res.data.data.robot_id, "1");
          } else {
            ElMessage.error(res.data.msg);
          }
        });
      } else {
        var data: any = {
          id: addid.value,
          robot_id: addrotmodel.value,
          name: addrotname.value,
          prompt: addrotprompt.value,
          greetings: addrotgreeting.value,
          logo: addavatar.value,
          describe: adddescribe.value,
          messages: [],
          prompt_is_publish: addrotswitchs,
        };
        if (addavatar.value == "") {
          data = {
            id: addid.value,
            robot_id: addrotmodel.value,
            name: addrotname.value,
            prompt: addrotprompt.value,
            greetings: addrotgreeting.value,
            describe: adddescribe.value,
            messages: [],
            prompt_is_publish: addrotswitchs,
          };
        }
        editrobot(data).then((res) => {
          if (res.data.code == 0) {
            drawer.value = false;
            ElMessage.success("编辑成功");
            addrotname.value = "";
            addrotprompt.value = "";
            addrotmodel.value = "";
            addrotgreeting.value = "";
            adddescribe.value = "";
            addavatar.value = "";
            robotlist("1");
            getrobotinfo({
              robot_id: addid.value,
            }).then((res) => {
              infojson.json = res.data.data;
            });
          } else {
            ElMessage.error(res.data.msg);
          }
        });
      }
    };
    const collectrobot = (e: any, type: any) => {
      collect({
        robot_id: e,
        type: type,
      }).then((res) => {
        if (res.data.code == 0) {
          if (type == 1) {
            ElMessage.success("收藏成功");
          } else {
            ElMessage.success("取消收藏成功");
          }
          getrobotinfo({
            robot_id: e,
          }).then((res) => {
            infojson.json = res.data.data;
          });
          robotlist("2");
          robotlist("3");
        } else {
          ElMessage.error("收藏失败");
        }
      });
    };
    // 删除机器人
    const removerobts = (e: any) => {
      delrobot({
        robot_id: e,
      }).then((res) => {
        if (res.data.code == 0) {
          robotlist("1");
          ElMessage.success("删除成功");
          handleCreateDialog();
          // tab(robot.myrobotList[0]);
        } else {
          ElMessage.error("删除失败");
        }
      });
    };
    const register = () => {
      iplogin().then((res) => {
        if (res.data.code == 0) {
          registerjson.list.organ_code = res.data.data.organ_code;
          registerDialog.value = true;
          retokens.value = "";
        } else {
          if (route.query.chatkey) {
            registerDialog.value = true;
            retokens.value = route.query.chatkey;
            registerjson.list.organ_code = route.query.organ_code;
          } else {
            window.location.href =
              "https://chatlibrary.newacademic.net/?chatchats=true";
          }
        }
      });
      // registerDialog.value = true;
    };
    // 注册帮助
    const registerHelp = () => {
      registerGuideModal.value = true;
    };
    //登录弹框
    const login = () => {
      loginDialog.value = true;
      usernameinput.value.focus();
    };
    function handleInput(e: any) {
      e.target.style.height = "auto";
    }
    function handleInput1(e: any) {
      // 如果输入内容是"/" 则弹出机器人列表
      if (e.target.value == "/") {
        openselectdialog.value = true;
        robotsearch.value = "";
        robotsearchsub();
        if (!localStorage.getItem("step2")) {
          localStorage.setItem("step2", "1");
          myrose.value = 2;
          setTimeout(() => {
            getstep2();
          }, 100);
        }
      }
      e.target.style.height = "auto";
    }
    const showRegisterModal = () => {
      loginDialog.value = false;
      // registerDialog.value = true;
      register();
    };
    const registeruser = () => {
      if (!registerjson.list.username) {
        ElMessage.error("请输入账号/用户名");
        return;
      }
      if (!registerjson.list.password) {
        ElMessage.error("请输入密码");
        return;
      }
      if (!registerjson.list.repassword) {
        ElMessage.error("请再次输入密码");
        return;
      }
      if (!registerjson.list.email) {
        ElMessage.error("请输入邮箱");
        return;
      }
      if (!registerjson.list.phone) {
        ElMessage.error("请输入手机号");
        return;
      }
      if (!registerjson.list.code) {
        ElMessage.error("请输入手机验证码");
        return;
      }
      if (registerjson.list.password != registerjson.list.repassword) {
        ElMessage.error("两次密码不一致");
        return;
      }
      if (!registerFormRef?.value) return;
      registerFormRef?.value?.validate((valid) => {
        if (valid) {
          registerapi({
            username: registerjson.list.username,
            password: registerjson.list.password,
            email: registerjson.list.email,
            phone: registerjson.list.phone,
            code: registerjson.list.code,
            organ_code: registerjson.list.organ_code,
            token: retokens.value,
          }).then((res) => {
            if (res.data.code == 0) {
              ElMessage.success("注册成功");
              registerDialog.value = false;
              registerjson.list = {
                username: "",
                password: "",
                repassword: "",
                email: "",
                phone: "",
                code: "",
                organ_code: "",
              };
            } else {
              ElMessage.error(res.data.msg);
            }
          });
        }
      });
    };
    const loginuser = () => {
      if (!formLabelAlign.username) {
        ElMessage.error("请输入账号/用户名");
        return;
      }
      if (!formLabelAlign.password) {
        ElMessage.error("请输入密码");
        return;
      }
      logins({
        username: formLabelAlign.username,
        password: formLabelAlign.password,
      }).then((res) => {
        if (res.data.code == 0) {
          // 如果role 数组中查找字符串15
          var role = res.data.data.role;
          if (!role.ChatModels) {
            ElMessage.error("您的账号没有体验畅聊的权限");
            return;
          }
          const secretKey = JSON.stringify(res.data.data.role);
          const cryDataRole = encodeURIComponent(secretKey);

          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("userName", res.data.data.username);
          localStorage.setItem("organ_name", res.data.data.organ_name);
          localStorage.setItem("organ_code", res.data.data.organ_code);
          localStorage.setItem("user_type", res.data.data.user_type);
          localStorage.setItem("user_id", res.data.data.user_id);
          localStorage.setItem("role_jump", cryDataRole);
          localStorage.setItem("role", JSON.stringify(res.data.data.role));
          userRole.value = res.data.data.role;
          token.value = res.data.data.token;
          username.value = res.data.data.username;
          loginDialog.value = false;
          ElMessage.success("登录成功");
          if (localStorage.getItem("step1")) {
            getchangelogs();
          }
          getchatlists("none");
          robotlist("1");
          robotlist("2");
          robotlist("3");
          modelslist();
          getmasklists();
          getqatime();
          // store.state.qatimeLoop = setInterval(() => {
          //   getqatime();
          // }, 10000);
          getmasklistall();
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 宣传页面发送消息（假）
    const sendmessage3 = () => {
      if (!token.value) {
        loginDialog.value = true;
        ElMessage.error("请先登录");
      }
    };
    //宣传页面发送消息
    const handleClose2 = () => {
      maskselectdialog.value = false;
      myrobotdialog.value = false;
      loginDialog.value = false;
      registerDialog.value = false;
      registerjson.list = {
        username: "",
        password: "",
        repassword: "",
        email: "",
        phone: "",
        code: "",
        organ_code: "",
      };
      mask_id.value = "";
      // addselectlistdialog.value = false;
      lookpamartdialog.value = false;
    };
    // 清除首页选中的面具
    const clearhomeask = () => {
      homemaskname.value = "";
      homemaskid.value = 0;
      homeaskindex.value = 0;
    };
    const handleCloseopen = () => {
      openselectdialog.value = false;
      versiondialog.value = false;
    };
    const handleClose = () => {
      drawer.value = false;
      addrotname.value = "";
      addrotprompt.value = "";
      addrotmodel.value = "";
      addrotgreeting.value = "";
      adddescribe.value = "";
    };
    //获取机器人列表
    const robotlist = (e: any) => {
      if (e == "2") {
        maskrobotListdialog.value = true;
      }
      var json: any = {
        page: robotlistpage.value,
        size: 2000,
        type: e,
      };
      if (e == 3) {
        json = {
          page: robotlistpage.value,
          size: 2000,
          type: e,
          sortfiled: "create_time",
        };
      }
      getrobotlist(json).then((res) => {
        if (res.data.code == 0) {
          if (e == "3") {
            robot.asideList = res.data.data.data;
          } else if (e == "2") {
            maskrobotListdialog.value = false;
            robot.maskrobotList = res.data.data.data;
          } else {
            robot.myrobotList = res.data.data.data;
            searchrobotlist.myrobotlist = res.data.data.data;
          }
        } else {
          console.log(res);
        }
      });
    };
    // 获取会话列表
    const getchatlists = (e: any) => {
      getchatlist({
        page: chatpage.value,
        size: 20,
      }).then((res) => {
        if (res.data.code == 0) {
          robot.chatList = res.data.data.data;
          chattotal.value = res.data.data.total;
        } else {
          console.log(res);
        }
      });
    };
    // 获取mask列表
    const getmasklistall = () => {
      getmasklist({
        page: 1,
        size: 10,
        robotname: robotsearch.value,
        sortfiled: robotselect.value,
        show_type: 2,
      }).then((res) => {
        searchrobotlist.alllist = res.data.data.data;
      });
    };
    const getmasklists = () => {
      getmasklist({
        page: maskpage.value,
        size: 10,
      }).then((res) => {
        if (res.data.code == 0) {
          robot.maskList = res.data.data.data;
          searchrobotlist.masklist = res.data.data.data;
          if (!localStorage.getItem("step1")) {
            localStorage.setItem("step1", "1");
            getstep1();
          }
        } else {
          console.log(res);
        }
      });
    };
    // 首页基座选择
    const homeselect = (e: any) => {
      if (homeactivelist.list.length == 0) {
        homeactivelist.list.push(e);
      } else {
        // 如果homeactivelist.list.length为1则且不等于第一个值则添加
        if (homeactivelist.list.length == 1 && homeactivelist.list[0] != e) {
          homeactivelist.list.push(e);
        } else if (
          homeactivelist.list.length == 1 &&
          homeactivelist.list[0] == e
        ) {
          homeactivelist.list = [];
        } else if (homeactivelist.list.length == 2) {
          if (e == homeactivelist.list[1]) {
            homeactivelist.list.splice(1, 1);
          } else if (e == homeactivelist.list[0]) {
            homeactivelist.list.splice(0, 1);
          }
        }
      }
    };
    // 获取基座列表
    const modelslist = () => {
      getmodelslist({
        size: 300,
      }).then((res) => {
        if (res.data.code == 0) {
          robot.modelslist = res.data.data.data;
        } else if (res.data.code == 400) {
          token.value = "";
        }
      });
    };
    // 首页面具选中
    const homemaskselect = (e: any) => {
      openselectdialog.value = false;
      homeaskindex.value = 0;
      homemaskid.value = e.id;
      homemaskname.value = e.title;
      homemasklogo.value = e.logo;
    };
    // 首页展开收起
    const showhide = (e: any) => {
      homemaskactive.value = e;
    };
    const showhidedia = (e: any) => {
      homemaskactivedialog.value = e;
    };
    const sendmessage2 = (item?: any) => {
      if (question.value == "" || question.value == "\n") {
        ElMessage.error("请输入内容");
        question.value = "";
        return;
      }
      if (selectrobotlist.list.length == 2) {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 1) {
          ElMessage.error("当前提问次数已经不支持对照问答");
          question.value = "";
          return;
        }
      } else {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
      }
      if (homeactivelist.list.length == 0) {
        ElMessage.error("请选择至少一个基础模型");
        return;
      }
      if (homemaskid.value == 0) {
        // 如果没有选中面具
        if (homeactivelist.list.length == 1) {
          // 如果只选中了一个基座
          robot.asideList.forEach((item: any, index: any) => {
            if (item.robot_id == robot.modelslist[homeactivelist.list[0]].id) {
              createchat({
                // 创建会话
                robot_id: item.id,
              }).then(async (res) => {
                if (res.data.code == 0) {
                  robot_chat_id_one.value = res.data.data.robot_chat_id;
                  const success = await Channel("none");
                  if (success == "连接成功") {
                    chattab(item);
                    chatsendmessage();
                  }
                } else {
                  ElMessage.error("创建失败");
                }
              });
            }
          });
        } else {
          robot.asideList.forEach((item: any, index: any) => {
            if (item.robot_id == robot.modelslist[homeactivelist.list[0]].id) {
              createchat({
                robot_id: item.id,
              }).then((res) => {
                if (res.data.code == 0) {
                  robot_chat_id_one.value = res.data.data.robot_chat_id;
                  selectrobotlist.list = [];
                  selectrobotlist.list.push(item);
                  answer[robot_chat_id_one.value] = [
                    {
                      qus: "",
                      answer: item.greetings,
                      logo: item.logo,
                    },
                  ];
                  robot.asideList.forEach((items: any, indexs: any) => {
                    if (
                      items.robot_id ==
                      robot.modelslist[homeactivelist.list[1]].id
                    ) {
                      createchat({
                        robot_id: items.id,
                      }).then(async (json) => {
                        if (json.data.code == 0) {
                          robot_chat_id_two.value =
                            json.data.data.robot_chat_id;
                          selectrobotlist.list.push(items);
                          answer[robot_chat_id_two.value] = [
                            {
                              qus: "",
                              answer: items.greetings,
                              logo: items.logo,
                            },
                          ];
                          const success1 = await Channel("1");
                          const success2 = await Channel("2");
                          if (
                            success1 == "连接成功" &&
                            success2 == "连接成功"
                          ) {
                            rightshow.value = 2;
                            chattype.value = "chat";
                            morechattype.value = "chat";
                            moresendmessage();
                          }
                        } else {
                          ElMessage.error("创建失败");
                        }
                      });
                    }
                  });
                } else {
                  ElMessage.error("创建失败");
                }
              });
            }
          });
        }
      } else {
        if (homeactivelist.list.length == 1) {
          robot.maskrobotList.forEach((item: any, index: any) => {
            if (
              item.robot_id == robot.modelslist[homeactivelist.list[0]].id &&
              item.robots_mask_id == homemaskid.value
            ) {
              createchat({
                robot_id: item.id,
              }).then(async (res) => {
                if (res.data.code == 0) {
                  robot_chat_id_one.value = res.data.data.robot_chat_id;
                  Channel("none");
                  const success = await Channel("none");
                  if (success == "连接成功") {
                    chattab(item);
                    chatsendmessage();
                  }
                } else {
                  ElMessage.error("创建失败");
                }
              });
            }
          });
        } else {
          robot.maskrobotList.forEach((item: any, index: any) => {
            if (
              item.robot_id == robot.modelslist[homeactivelist.list[0]].id &&
              item.robots_mask_id == homemaskid.value
            ) {
              createchat({
                robot_id: item.id,
              }).then((res) => {
                if (res.data.code == 0) {
                  robot_chat_id_one.value = res.data.data.robot_chat_id;
                  selectrobotlist.list = [];
                  selectrobotlist.list.push(item);
                  answer[robot_chat_id_one.value] = [
                    {
                      qus: "",
                      answer: item.greetings,
                      logo: item.logo,
                    },
                  ];
                  robot.maskrobotList.forEach((items: any, indexs: any) => {
                    if (
                      items.robot_id ==
                        robot.modelslist[homeactivelist.list[1]].id &&
                      items.robots_mask_id == homemaskid.value
                    ) {
                      createchat({
                        robot_id: items.id,
                      }).then(async (json) => {
                        if (json.data.code == 0) {
                          robot_chat_id_two.value =
                            json.data.data.robot_chat_id;
                          selectrobotlist.list.push(items);
                          answer[robot_chat_id_two.value] = [
                            {
                              qus: "",
                              answer: items.greetings,
                              logo: items.logo,
                            },
                          ];
                          const success1 = await Channel("1");
                          const success2 = await Channel("2");
                          if (
                            success1 == "连接成功" &&
                            success2 == "连接成功"
                          ) {
                            rightshow.value = 2;
                            chattype.value = "chat";
                            morechattype.value = "chat";
                            moresendmessage();
                          }
                        } else {
                          ElMessage.error("创建失败");
                        }
                      });
                    }
                  });
                } else {
                  ElMessage.error("创建失败");
                }
              });
            }
          });
        }
      }
    };
    const isScrollbarAtBottom = (e: any) => {
      try {
        var element = null;
        if (e == "questionref") {
          element = questionref.value;
        } else if (e == "questionref0") {
          element = questionref0.value[0];
        } else {
          element = questionref1.value[0];
        }
        var scrollHeight = element.scrollHeight;
        var clientHeight = element.clientHeight;
        var scrollTop = element.scrollTop;
        return scrollTop === scrollHeight - clientHeight;
      } catch (error) {
        console.log("未找到元素");
      }
    };
    const scrollBottm = (e: any) => {
      // 如果id为div的元素滚动条位于底部
      // msg-box 是你要滚动的div的id
      if (e == "questionref") {
        if (isScrollbarAtBottom("questionref")) {
          setTimeout(function () {
            const el = questionref.value;
            if (el.scrollHeight) {
              el.scrollTop = el.scrollHeight + 100;
            }
          }, 50);
        }
      } else if (e == "questionref0") {
        if (isScrollbarAtBottom("questionref0")) {
          setTimeout(function () {
            const el = questionref0.value[0];
            if (el.scrollHeight) {
              el.scrollTop = el.scrollHeight + 100;
            }
          }, 50);
        }
      } else {
        if (isScrollbarAtBottom("questionref1")) {
          setTimeout(function () {
            const el = questionref1.value[0];
            if (el.scrollHeight) {
              el.scrollTop = el.scrollHeight + 100;
            }
          }, 50);
        }
      }
    };
    const autoScroll = (e: any) => {
      if (e == "questionref") {
        setTimeout(() => {
          questionref.value.scrollTop = questionref.value.scrollHeight;
        }, 100);
      } else if (e == "questionref0") {
        setTimeout(() => {
          questionref0.value[0].scrollTop = questionref0.value[0].scrollHeight;
        }, 100);
      } else {
        setTimeout(() => {
          questionref1.value[0].scrollTop = questionref1.value[0].scrollHeight;
        }, 100);
      }
    };
    const tabmore = (e: any) => {
      clearheartbeattime();
      rightshow.value = 2;
      if (chattype.value == "robot") {
        answer["临时2"] = [
          {
            ques: "",
            answer: [
              {
                id: null,
                create_date: "",
                text: e.greetings,
              },
            ],
            logo: e.logo,
            iconShow: false,
            current: 0,
          },
        ];
        morechattype.value = "robot";
      } else {
        answer["临时2"] = [
          {
            ques: "",
            answer: [
              {
                id: null,
                create_date: "",
                text: e.greetings,
              },
            ],
            logo: e.logo,
            iconShow: false,
            current: 0,
          },
        ];
        morechattype.value = "chatandrobot";
      }
      selectrobotlist.list.push(e);
    };
    const chatidopen = (e: any) => {
      selectrobotlist.list = [];
      getrobotinfo({
        robot_id: e.robot_id,
      }).then((res) => {
        infojson.json = res.data.data;
        if (infojson.json.type == 1) {
          robot.myrobotList.forEach((item: any, index: any) => {
            if (item.id == e.robot_id) {
              selectrobotlist.list.push(item);
            }
          });
        } else if (infojson.json.type == 2) {
          robot.maskrobotList.forEach((item: any, index: any) => {
            if (item.id == e.robot_id) {
              selectrobotlist.list.push(item);
            }
          });
        } else {
          robot.asideList.forEach((item: any, index: any) => {
            if (item.id == e.robot_id) {
              selectrobotlist.list.push(item);
            }
          });
        }
        if (!answer[e.id]) {
          answer[e.id] = [];
        }
        var json = {
          page: page.value,
          size: 10,
          robot_chat_id: e.id,
        };
        // 获取历史数据
        getmsghistory(json).then(async (res) => {
          var data = res.data.data.data;
          rightshow.value = 1;
          chattype.value = "chat";
          if (data.length == 0) {
            answer[e.id] = [
              {
                qus: "",
                answer: infojson.json.greetings,
                // logo: selectrobotlist.list[0].logo,
                logo: e.logo,
              },
            ];
            return;
          }
          var answers: any = [];
          total.value = res.data.data.total;
          // data 倒叙
          data.reverse();
          data.forEach((item: any, index: number) => {
            item.answer.reverse();
            item.answer.forEach((v: any) => {
              v.text = v.text.replace("|", "\n");
              v.text = v.text.replaceAll("||", "|\n|");
              v.text = v.text.replace(/\|/g, "| ");
              let mapperHtml: any = marked(v.text);
              mapperHtml = mapperHtml.replaceAll(
                "<table>",
                '<table class="chat-table">'
              );
              v.text = mapperHtml;
            });
            answers.push({
              qus: item.question,
              answer: item.answer,
              // logo: item.logo || selectrobotlist.list[0].logo,
              logo: item.logo || e.logo,
              iconShow: true,
              current: 0,
            });
          });
          // if (!answer[e.id]) {
          answer[e.id] = answers;
          // } else {
          //   answer[e.id] = answers.concat(
          //     answer[e.id]
          //   );
          // }
          robot_chat_id_one.value = e.id;
          const success = await Channel("none");
          if (page.value == 1) {
            autoScroll("questionref");
          }
        });
      });
    };
    const chattab = (e: any) => {
      rightshow.value = 1;
      chattype.value = "chat";
      selectrobotlist.list = [];
      robotinfo.value = true; //机器人详情展示
      selectrobotlist.list.push(e);
      getinfojson(e.id);
    };
    const totab = (e: any) => {
      openselectdialog.value = false;
      tab(e);
    };
    // 列表选中机器人
    const tab = async (e: any, from?: string) => {
      // 当前选中和切换的机器人一致 直接return
      if (
        e.id === infojson.json.id &&
        !["create", "new-chat"].includes(from || "")
      )
        return;
      handlePublic(); // 调用公共方法
      store.state.setRobotData = "";
      rightshow.value = 1;
      chattype.value = "robot";
      selectrobotlist.list = [];
      robotinfo.value = true; //机器人详情展示
      answer["临时1"] = [
        {
          ques: "",
          answer: [
            {
              id: null,
              create_date: "",
              text: e.greetings,
            },
          ],
          logo: e.logo,
          iconShow: false,
          current: 0,
        },
      ];
      selectrobotlist.list.push(e);
      myrobotdialog.value = false;
      getinfojson(e.id);
    };
    const closerobot = async (index: number) => {
      if (store.state.setRobotData2 != "" && store.state.setRobotData1 != "") {
        ElMessage.error("请等待回答结束");
        return;
      }
      selectrobotlist.list.splice(index, 1);
      if (morechattype.value == "chat") {
        chattype.value = "chat";
        if (index == 0) {
          closeWebSocket();
          closeWebSocket2();
          clearInterval(morelchannel_id.heartbeatInterval1);
          clearInterval(morelchannel_id.heartbeatInterval2);
          var robot_chat_id = robot_chat_id_two.value;
          robot_chat_id_one.value = robot_chat_id;
          robot_chat_id_two.value = "";
          var id = morelchannel_id.channel_id2;
          localStorage.setItem("channel_id", id);
          morelchannel_id.channel_id1 = id;
          morelchannel_id.channel_id2 = "";
          morelchannel_id.heartbeatInterval1 = null;
          morelchannel_id.heartbeatInterval2 = null;
          morelchannel_id.isHeartbeatRunning1 = false;
          morelchannel_id.isHeartbeatRunning2 = false;
          const success = await Channel("1");
          console.log(success);
        } else {
          closeWebSocket2();
          clearInterval(morelchannel_id.heartbeatInterval2);
          morelchannel_id.channel_id2 = "";
          morelchannel_id.heartbeatInterval2 = null;
          morelchannel_id.isHeartbeatRunning2 = false;
        }
      }
      if (morechattype.value == "chatandrobot") {
        if (index == 0) {
          chattype.value = "robot";
          morechattype.value = "robot";
          var list = answer["临时2"];
          answer["临时1"] = list;
          answer["临时2"] = [];
          closeWebSocket();
          closeWebSocket2();
          clearInterval(morelchannel_id.heartbeatInterval1);
          clearInterval(morelchannel_id.heartbeatInterval2);
          morelchannel_id.channel_id1 = "";
          morelchannel_id.channel_id2 = "";
          morelchannel_id.heartbeatInterval1 = null;
          morelchannel_id.heartbeatInterval2 = null;
          morelchannel_id.isHeartbeatRunning1 = false;
          morelchannel_id.isHeartbeatRunning2 = false;
        } else {
          chattype.value = "chat";
          if (!morelchannel_id.channel_id1) {
            morelchannel_id.channel_id1 = localStorage.getItem("channel_id");
          }
        }
      }
      if (morechattype.value == "robot") {
        chattype.value = "robot";
      }
    };
    const clearheartbeattime = () => {
      clearInterval(heartbeatInterval);
    };
    const getinfojson = (e: any) => {
      getrobotinfo({
        robot_id: e,
      }).then((res) => {
        infojson.json = res.data.data;
        if (!localStorage.getItem("step3") && infojson.json.type !== 1) {
          localStorage.setItem("step3", "1");
          setTimeout(() => {
            getstep3();
          }, 100);
        }
      });
    };
    const sendmessage = () => {
      if (question.value == "" || question.value == "\n") {
        question.value = "";
        ElMessage.error("请输入问题");
        return;
      }
      if (selectrobotlist.list.length == 2) {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 1) {
          ElMessage.error("当前提问次数已经不支持对照问答");
          question.value = "";
          return;
        }
      } else {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
      }
      createchat({
        // 创建会话
        robot_id: selectrobotlist.list[0].id,
      }).then(async (res) => {
        if (res.data.code == 0) {
          robot_chat_id_one.value = res.data.data.robot_chat_id;
          const success = await Channel("none");
          if (!answer[robot_chat_id_one.value]) {
            let text = selectrobotlist.list[0].greetings;
            answer[robot_chat_id_one.value] = [
              {
                qus: "",
                answer: [
                  {
                    id: null,
                    create_date: "",
                    text,
                  },
                ],
                logo: selectrobotlist.list[0].logo,
                iconShow: false,
                current: 0,
              },
            ];
            answer[robot_chat_id_one.value].push({
              qus: question.value,
              answer: [
                {
                  id: null,
                  create_date: "",
                  text: "正在查询中...",
                },
              ],
              logo: selectrobotlist.list[0].logo,
              iconShow: false,
              current: 0,
            });
          } else {
            answer[robot_chat_id_one.value].push({
              qus: question.value,
              answer: {
                id: null,
                create_date: "",
                text: "正在查询中...",
              },
              logo: selectrobotlist.list[0].logo,
              iconShow: false,
              current: 0,
            });
          }
          chattype.value = "chat";
          if (success == "连接成功") {
            robotqs({
              query: question.value,
              channel_id: localStorage.getItem("channel_id"),
              robot_chat_id: robot_chat_id_one.value,
              history: ishistory.value,
            });

            ishistory.value = true;
            question.value = "";
            setTimeout(() => {
              autoScroll("questionref");
            }, 100);
          }
        } else {
          ElMessage.error("创建失败");
        }
      });
    };
    // 发送聊天消息
    const chatsendmessage = (item?: any) => {
      // item不存在 为重新生成
      if (!item) {
        if (nowsbeck.value == true) {
          question.value = "";
          ElMessage.error("请等待回答结束");
          return;
        }
        if (question.value == "" || question.value == "\n") {
          question.value = "";
          ElMessage.error("请输入问题");
          return;
        }
      }
      if (selectrobotlist.list.length == 2) {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 1) {
          ElMessage.error("当前提问次数已经不支持对照问答");
          question.value = "";
          return;
        }
      } else {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
      }
      if (!answer[robot_chat_id_one.value]) {
        answer[robot_chat_id_one.value] = [
          {
            qus: "",
            answer: [
              {
                id: null,
                create_date: "",
                text: selectrobotlist.list[0].greetings
                  ? selectrobotlist.list[0].greetings
                  : "",
              },
            ],
            logo: selectrobotlist.list[0].logo,
            iconShow: false,
            current: 0,
          },
        ];
      }
      let params: Params = {
        query: "",
        channel_id: localStorage.getItem("channel_id"),
        robot_chat_id: robot_chat_id_one.value,
        history: ishistory.value,
        msg_id: null,
      };
      if (!item) {
        answer[robot_chat_id_one.value].push({
          qus: question.value,
          answer: [
            {
              id: null,
              create_date: "",
              text: "正在查询中...",
            },
          ],
          logo: selectrobotlist.list[0].logo,
          iconShow: false,
          current: 0,
        });
        params.query = question.value;
        delete params.msg_id;
      } else {
        item.iconShow = false;
        item.answer.push({
          id: null,
          create_date: "",
          text: "正在查询中...",
        });
        params.msg_id = item.answer[0].id;
        item.current = item.answer.length - 1;
        params.query = item.qus;
      }
      robotqs(params);
      ishistory.value = true;
      if (!item) {
        question.value = "";
      }
      setTimeout(() => {
        autoScroll("questionref");
      }, 100);
    };
    // 对照聊天方法
    const moresendmessage = (item?: any, num?: any) => {
      if (!item) {
        if (nowsbeck2.value == true) {
          question.value = "";
          ElMessage.error("请等待回答结束");
          return;
        }
        if (question.value == "" || question.value == "\n") {
          question.value = "";
          ElMessage.error("请输入问题");
          return;
        }
      }
      if (selectrobotlist.list.length == 2) {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 1) {
          ElMessage.error("当前提问次数已经不支持对照问答");
          question.value = "";
          return;
        }
      } else {
        if (times.value.organ_times == false) {
          ElMessage.error("您所在机构今日提问次数已用完，请明日使用");
          question.value = "";
          return;
        }
        if (times.value.times == 0) {
          ElMessage.error("您今日的使用次数已到上限，请明日使用");
          question.value = "";
          return;
        }
      }

      if (morechattype.value == "robot") {
        if (selectrobotlist.list.length == 2) {
          createchat({
            // 创建会话
            robot_id: selectrobotlist.list[0].id,
          }).then((res) => {
            if (res.data.code == 0) {
              robot_chat_id_one.value = res.data.data.robot_chat_id;
              createchat({
                // 创建会话
                robot_id: selectrobotlist.list[1].id,
              }).then(async (json) => {
                robot_chat_id_two.value = json.data.data.robot_chat_id;
                const success1 = await Channel("1");
                const success2 = await Channel("2");
                let text_1 = selectrobotlist.list[0].greetings;
                answer[robot_chat_id_one.value] = [
                  {
                    qus: "",
                    answer: [{ id: null, create_date: "", text: text_1 }],
                    logo: selectrobotlist.list[0].logo,
                    iconShow: false,
                    current: 0,
                  },
                  {
                    qus: question.value,
                    answer: [
                      { id: null, create_date: "", text: "正在查询中..." },
                    ],
                    logo: selectrobotlist.list[0].logo,
                    iconShow: false,
                    current: 0,
                  },
                ];
                let text_2 = selectrobotlist.list[1].greetings;
                answer[robot_chat_id_two.value] = [
                  {
                    qus: "",
                    answer: [{ id: null, create_date: "", text: text_2 }],
                    logo: selectrobotlist.list[1].logo,
                    iconShow: false,
                    current: 0,
                  },
                  {
                    qus: question.value,
                    answer: [
                      { id: null, create_date: "", text: "正在查询中..." },
                    ],
                    logo: selectrobotlist.list[1].logo,
                    iconShow: false,
                    current: 0,
                  },
                ];
                morechattype.value = "chat";
                if (success1 == "连接成功" && success2 == "连接成功") {
                  robotqs({
                    query: question.value,
                    channel_id: morelchannel_id.channel_id1,
                    robot_chat_id: robot_chat_id_one.value,
                    history: ishistory.value,
                  });
                  robotqs({
                    query: question.value,
                    channel_id: morelchannel_id.channel_id2,
                    robot_chat_id: robot_chat_id_two.value,
                    history: ishistory.value,
                  });
                  ishistory.value = true;
                  question.value = "";
                  setTimeout(() => {
                    autoScroll("questionref0");
                    autoScroll("questionref1");
                  }, 100);
                }
              });
            } else {
              ElMessage.error("创建失败");
            }
          });
        } else {
          createchat({
            // 创建会话
            robot_id: selectrobotlist.list[0].id,
          }).then(async (res) => {
            if (res.data.code == 0) {
              robot_chat_id_one.value = res.data.data.robot_chat_id;
              const success = await Channel("1");
              let text = selectrobotlist.list[0].greetings;
              answer[robot_chat_id_one.value] = [
                {
                  qus: "",
                  answer: [{ id: null, create_date: "", text }],
                  logo: selectrobotlist.list[0].logo,
                  iconShow: false,
                  current: 0,
                },
                {
                  qus: question.value,
                  answer: [
                    { id: null, create_date: "", text: "正在查询中..." },
                  ],
                  logo: selectrobotlist.list[0].logo,
                  iconShow: false,
                  current: 0,
                },
              ];
              morechattype.value = "chatandrobot";
              if (success == "连接成功") {
                robotqs({
                  query: question.value,
                  channel_id: morelchannel_id.channel_id1,
                  robot_chat_id: robot_chat_id_one.value,
                  history: ishistory.value,
                });
                ishistory.value = true;
                question.value = "";
                setTimeout(() => {
                  autoScroll("questionref0");
                }, 100);
              }
            } else {
              ElMessage.error("创建失败");
            }
          });
        }
      } else if (morechattype.value == "chatandrobot") {
        createchat({
          // 创建会话
          robot_id: selectrobotlist.list[1].id,
        }).then(async (json) => {
          robot_chat_id_two.value = json.data.data.robot_chat_id;
          if (!morelchannel_id.channel_id1) {
            morelchannel_id.channel_id1 = localStorage.getItem("channel_id");
          }
          const success = await Channel("2");
          let text = selectrobotlist.list[1].greetings;

          if (!item) {
            answer[robot_chat_id_two.value] = [
              {
                qus: "",
                answer: [{ id: null, create_date: "", text }],
                logo: selectrobotlist.list[1].logo,
                iconShow: false,
                current: 0,
              },
              {
                qus: question.value,
                answer: [{ id: null, create_date: "", text: "正在查询中..." }],
                logo: selectrobotlist.list[1].logo,
                iconShow: false,
                current: 0,
              },
            ];
            answer[robot_chat_id_one.value].push({
              qus: question.value,
              answer: [{ id: null, create_date: "", text: "正在查询中..." }],
              logo: selectrobotlist.list[0].logo,
              iconShow: false,
              current: 0,
            });
          } else {
            item.iconShow = false;
            item.answer.push({
              id: null,
              create_date: "",
              text: "正在查询中...",
            });
            item.current = item.answer.length - 1;
          }
          morechattype.value = "chat";
          if (success == "连接成功") {
            let params: Params = {
              query: question.value,
              channel_id: morelchannel_id.channel_id1,
              robot_chat_id: robot_chat_id_one.value,
              history: ishistory.value,
              msg_id: null,
            };
            if (item) {
              item.iconShow = false;
              item.current = item.answer.length - 1;
              params.msg_id = item.answer[0].id;
              params.query = item.qus;
              robotqs(params);
              ishistory.value = true;
              if (!item) {
                question.value = "";
              }
            } else {
              robotqs({
                query: question.value,
                channel_id: morelchannel_id.channel_id1,
                robot_chat_id: robot_chat_id_one.value,
                history: ishistory.value,
              });
              robotqs({
                query: question.value,
                channel_id: morelchannel_id.channel_id2,
                robot_chat_id: robot_chat_id_two.value,
                history: ishistory.value,
              });
              ishistory.value = true;
              question.value = "";
              setTimeout(() => {
                autoScroll("questionref0");
                autoScroll("questionref1");
              }, 100);
            }
          }
        });
      } else {
        if (selectrobotlist.list.length == 2) {
          if (!item) {
            answer[robot_chat_id_one.value].push({
              qus: question.value,
              answer: [{ id: null, create_date: "", text: "正在查询中..." }],
              logo: selectrobotlist.list[0].logo,
              iconShow: false,
              current: 0,
            });
            answer[robot_chat_id_two.value].push({
              qus: question.value,
              answer: [{ id: null, create_date: "", text: "正在查询中..." }],
              logo: selectrobotlist.list[1].logo,
              iconShow: false,
              current: 0,
            });
            robotqs({
              query: question.value,
              channel_id: morelchannel_id.channel_id1,
              robot_chat_id: robot_chat_id_one.value,
              history: ishistory.value,
            });
            robotqs({
              query: question.value,
              channel_id: morelchannel_id.channel_id2,
              robot_chat_id: robot_chat_id_two.value,
              history: ishistory.value,
            });
            ishistory.value = true;
            question.value = "";
            setTimeout(() => {
              autoScroll("questionref0");
              autoScroll("questionref1");
            }, 100);
          } else {
            if (num == 1) {
              let params: Params = {
                query: "",
                channel_id: morelchannel_id.channel_id1,
                robot_chat_id: robot_chat_id_one.value,
                history: ishistory.value,
                msg_id: null,
              };
              item.iconShow = false;
              item.answer.push({
                id: null,
                create_date: "",
                text: "正在查询中...",
              });
              params.msg_id = item.answer[0].id;
              item.current = item.answer.length - 1;
              params.query = item.qus;
              robotqs(params);
              ishistory.value = true;
              setTimeout(() => {
                autoScroll("questionref0");
              }, 100);
            } else {
              let params: Params = {
                query: "",
                channel_id: morelchannel_id.channel_id2,
                robot_chat_id: robot_chat_id_two.value,
                history: ishistory.value,
                msg_id: null,
              };
              item.iconShow = false;
              item.answer.push({
                id: null,
                create_date: "",
                text: "正在查询中...",
              });
              params.msg_id = item.answer[0].id;
              item.current = item.answer.length - 1;
              params.query = item.qus;
              robotqs(params);
              ishistory.value = true;
              setTimeout(() => {
                autoScroll("questionref1");
              }, 100);
            }
          }
        } else {
          let params: Params = {
            query: "",
            channel_id: localStorage.getItem("channel_id"),
            robot_chat_id: robot_chat_id_one.value,
            history: ishistory.value,
            msg_id: null,
          };
          if (!item) {
            answer[robot_chat_id_one.value].push({
              qus: question.value,
              answer: [
                {
                  id: null,
                  create_date: "",
                  text: "正在查询中...",
                },
              ],
              logo: selectrobotlist.list[0].logo,
              iconShow: false,
              current: 0,
            });
            params.query = question.value;
            delete params.msg_id;
          } else {
            item.iconShow = false;
            item.answer.push({
              id: null,
              create_date: "",
              text: "正在查询中...",
            });
            params.msg_id = item.answer[0].id;
            item.current = item.answer.length - 1;
            params.query = item.qus;
          }
          answer[robot_chat_id_one.value].push({
            qus: question.value,
            answer: [{ id: null, create_date: "", text: "正在查询中..." }],
            logo: selectrobotlist.list[0].logo,
            iconShow: false,
            current: 0,
          });
          robotqs({
            query: question.value,
            channel_id: morelchannel_id.channel_id1,
            robot_chat_id: robot_chat_id_one.value,
            history: ishistory.value,
          });
          ishistory.value = true;
          question.value = "";
          setTimeout(() => {
            autoScroll("questionref0");
          }, 100);
        }
      }
    };
    const confirmEvent = () => {
      localStorage.removeItem("token");
      token.value = "";
    };
    const masktab = (e: any) => {
      selectrobotlist.list = [];
      mask_id.value = e;
      maskselectdialog.value = true;
      myrobotdialog.value = false;
      robot.maskrobotList.forEach((item: any, index: any) => {
        if (item.robots_mask_id == e) {
          robot.modelslist.forEach((items: any, indexs: any) => {
            if (item.robot_id == items.id) {
              items.coll = item.coll;
            }
          });
        }
      });
    };
    const addmaskmore = (e: any) => {
      maskselectdialog.value = false;
      if (mask_id.value) {
        robot.maskrobotList.forEach((item: any, index: any) => {
          if (item.robot_id == e && item.robots_mask_id == mask_id.value) {
            mask_id.value = "";
            tab(item);
          }
        });
      } else {
        if (infojson.json.type == 3 || !infojson.json.type) {
          robot.asideList.forEach((item: any, index: any) => {
            if (item.robot_id == e) {
              tabmore(item);
            }
          });
        } else {
          robot.maskrobotList.forEach((item: any, index: any) => {
            if (
              item.robot_id == e &&
              item.robots_mask_id == selectrobotlist.list[0].robots_mask_id
            ) {
              tabmore(item);
            }
          });
        }
      }
    };
    const heart = () => {
      if (rightshow.value == 1) {
        if (!isHeartbeatRunning) {
          isHeartbeatRunning = true;
          heartbeat({
            channel_id: localStorage.getItem("channel_id"),
          }).then((res) => {
            isHeartbeatRunning = false;
          });
        }
      } else {
        if (selectrobotlist.list.length == 2) {
          if (!morelchannel_id.isHeartbeatRunning1) {
            morelchannel_id.isHeartbeatRunning1 = true;
            heartbeat({
              channel_id: morelchannel_id.channel_id1,
            }).then((res) => {
              morelchannel_id.isHeartbeatRunning1 = false;
            });
          }
          if (!morelchannel_id.isHeartbeatRunning2) {
            morelchannel_id.isHeartbeatRunning2 = true;
            heartbeat({
              channel_id: morelchannel_id.channel_id2,
            }).then((res) => {
              morelchannel_id.isHeartbeatRunning2 = false;
            });
          }
        } else {
          if (!morelchannel_id.isHeartbeatRunning1) {
            morelchannel_id.isHeartbeatRunning1 = true;
            heartbeat({
              channel_id: morelchannel_id.channel_id1,
            }).then((res) => {
              morelchannel_id.isHeartbeatRunning1 = false;
            });
          }
        }
      }
    };
    // 初始化 WebSocket
    const Channel = async (e: any) => {
      try {
        let result: string | null = null;
        if (e == "none") {
          const res = await getChannel();
          localStorage.setItem("channel_id", res.data.data.channel_id);
          wsurl =
            store.state.wssURL +
            "/ws/chatchannel?token=" +
            localStorage.getItem("token") +
            "&channel_id=" +
            res.data.data.channel_id;

          await initWebSocketPromise(wsurl);
          result = "连接成功";
          // 开始计时 30s 发送一次心跳
          heartbeatInterval = setInterval(heart, 30000);
        } else if (e == "1") {
          const res = await getChannel();
          var channel_id = "";
          morelchannel_id.channel_id1 = res.data.data.channel_id;
          channel_id = morelchannel_id.channel_id1;
          wsurl =
            store.state.wssURL +
            "/ws/chatchannel?token=" +
            localStorage.getItem("token") +
            "&channel_id=" +
            channel_id;
          await initWebSocketPromise(wsurl);
          result = "连接成功";
          morelchannel_id.heartbeatInterval1 = setInterval(heart, 30000);
        } else if (e == "2") {
          const res = await getChannel();
          var channel_id = "";
          morelchannel_id.channel_id2 = res.data.data.channel_id;
          channel_id = morelchannel_id.channel_id2;
          wsurl =
            store.state.wssURL +
            "/ws/chatchannel?token=" +
            localStorage.getItem("token") +
            "&channel_id=" +
            channel_id;
          await initWebSocketPromise2(wsurl);
          result = "连接成功";
          morelchannel_id.heartbeatInterval2 = setInterval(heart, 30000);
        }
        return result;
      } catch (error) {
        // 处理错误，比如连接失败
        console.error(error);
        return "连接失败";
      }
    };
    // 使用 Promise 包装的 WebSocket 初始化函数
    const initWebSocketPromise = (wsUrl: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        initWebSocket(wsUrl, () => {
          resolve();
        });
      });
    };
    // 使用 Promise 包装的 WebSocket 初始化函数
    const initWebSocketPromise2 = (wsUrl: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        initWebSocket2(wsUrl, () => {
          resolve();
        });
      });
    };
    // 创建会话进入
    const keydown = (event: any) => {
      if (event.key === "Enter") {
        if (event.shiftKey) {
        } else {
          question.value = question.value.trim();
          event.preventDefault();
          sendmessage();
        }
      }
    };
    // 聊天记录进入
    const keydown1 = (event: any) => {
      if (event.key === "Enter") {
        if (event.shiftKey) {
        } else {
          question.value = question.value.trim();
          event.preventDefault();
          chatsendmessage();
        }
      }
    };
    // 初始对照进入
    const keydown2 = (event: any) => {
      if (event.key === "Enter") {
        if (event.shiftKey) {
        } else {
          question.value = question.value.trim();
          event.preventDefault();
          moresendmessage();
        }
      }
    };
    const keydown3 = (event: any) => {
      if (event.key === "Enter") {
        if (event.shiftKey) {
        } else {
          event.preventDefault();
          sendmessage2();
        }
      }
    };
    // 开启对照问答
    const addselect = (type: any) => {
      maskselectdialog.value = true;
      // addselectlist(item)
    };
    const addselectlist = (e: any) => {
      maskselectdialog.value = true;
    };
    const closemoretype = (e: any) => {
      if (morechattype.value == "robot") {
        tab(e);
      } else {
        chattab(e);
      }
    };
    // 停止回答
    const closebutton = async (e: any, index?: any) => {
      if (e == "none") {
        closeWebSocket();
        clearInterval(morelchannel_id.heartbeatInterval1);
        let channel_id = localStorage.getItem("channel_id");
        let { data } = await stopreply({ channel_id });
        if (data.code === 0) {
          let len = answer[robot_chat_id_one.value].length - 1;
          let answerLen =
            answer[robot_chat_id_one.value][len].answer.length - 1;
          answer[robot_chat_id_one.value][len].iconShow = true;
          answer[robot_chat_id_one.value][len].current = answerLen;
          if (rightshow.value == 2) {
            scrollBottm("questionref0");
          } else {
            scrollBottm("questionref");
          }
        }
        store.state.setRobotData = "";
        const success = await Channel("none");
        nowsbeck2.value = false;
        nowsbeck.value = false;
      } else {
        if (index == 0) {
          let channel_id1 = morelchannel_id.channel_id1;
          let { data } = await stopreply({ channel_id: channel_id1 });
          if (data.code === 0) {
            let len = answer[robot_chat_id_one.value].length - 1;
            let answerLen =
              answer[robot_chat_id_one.value][len].answer.length - 1;
            answer[robot_chat_id_one.value][len].iconShow = true;
            answer[robot_chat_id_one.value][len].current = answerLen;
            if (rightshow.value == 2) {
              scrollBottm("questionref0");
            } else {
              scrollBottm("questionref");
            }
          }
          closeWebSocket();
          clearInterval(morelchannel_id.heartbeatInterval1);
          store.state.setRobotData = "";
          const success1 = await Channel("1");
          nowsbeck.value = false;
        } else {
          let channel_id2 = morelchannel_id.channel_id2;
          let { data } = await stopreply({ channel_id: channel_id2 });
          if (data.code === 0) {
            let len = answer[robot_chat_id_two.value].length - 1;
            let answerLen =
              answer[robot_chat_id_two.value][len].answer.length - 1;
            answer[robot_chat_id_two.value][len].iconShow = true;
            answer[robot_chat_id_two.value][len].current = answerLen;
            if (rightshow.value == 2) {
              scrollBottm("questionref1");
            } else {
              scrollBottm("questionref");
            }
          }
          closeWebSocket2();
          clearInterval(morelchannel_id.heartbeatInterval2);
          store.state.setRobotData2 = "";
          const success2 = await Channel("2");
          nowsbeck2.value = false;
        }
      }
    };
    // 一键复制功能
    const handleCopy = () => {
      let clipboard = new Clipboard(".copy-btn", {
        text: function (trigger: any) {
          let content: any = trigger.getAttribute("data-clipboard-text");
          return content.replace(/<[^>]*>/g, "");
        },
      });
      clipboard.on("success", function (e: any) {
        e.clearSelection();
        ElMessage.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", function (e: any) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
        ElMessage.error("复制失败");
        clipboard.destroy();
      });
    };
    // 重新生成功能
    const handleRegenerate = (item: any, type: number) => {
      switch (type) {
        case 1:
          chatsendmessage(item); // 正常进入
          break;
        case 2:
          moresendmessage(item, 1); // 对照聊天左侧
          break;
        case 3:
          moresendmessage(item, 2); // 对照聊天右侧
          break;
        default:
          break;
      }
    };
    // 重新生成多个回答 箭头切换方法
    const handleAnswerChange = (item: any, type: string) => {
      if (type === "prev") {
        if (item.current === 0) return;
        item.current -= 1;
      } else {
        if (item.current === item.answer.length - 1) return;
        item.current += 1;
      }
    };

    // 点击卡片跳转对应网站
    const openCardUrl = (role: string, path: string) => {
      if (role === "ChatModels") {
        handleCreateDialog();
        return;
      }
      if (role == "ChatAcademic") {
        window.open(
          `${path}?userName=${localStorage.getItem(
            "userName"
          )}&user_id=${localStorage.getItem("user_id")}&token=${
            token.value
          }&organ_name=${localStorage.getItem(
            "organ_name"
          )}&organ_code=${localStorage.getItem(
            "organ_code"
          )}&user_type=${localStorage.getItem(
            "user_type"
          )}&role=${localStorage.getItem("role_jump")}`
        );
      } else {
        window.open(
          `${path}?userName=${localStorage.getItem(
            "username"
          )}&user_id=${localStorage.getItem("user_id")}&token=${token.value}`
        );
      }
    };

    // 创建新对话
    const handleCreateDialog = () => {
      rightshow.value = 0;
      infojson.json = {};
      handlePublic(); //
    };
    // 公共方法
    // 重置页码 停止回答
    const handlePublic = async () => {
      page.value = 1;
      if (nowsbeck.value) {
        clearheartbeattime();
        closeWebSocket();
        let channel_id = localStorage.getItem("channel_id");
        let { data } = await stopreply({ channel_id });
        if (data.code === 0) {
          nowsbeck.value = false;
        }
      }
    };
    const getchangelogs = async () => {
      let res = await getchangelog();
      if (res.data.code == 0) {
        var time = new Date().getTime();
        var versiontime = new Date(res.data.data.online_time).getTime();
        if (
          time >= versiontime &&
          res.data.data.online_time != localStorage.getItem("versiontime")
        ) {
          versiondialog.value = true;
          version.list = res.data.data;
          localStorage.setItem("versiontime", res.data.data.online_time);
        } else {
          versiondialog.value = false;
        }
      }
    };
    const toOther = (e: any) => {
      if (e == "ppt") {
        window.open(
          "https://chatppt.newacademic.net/#/?token=" +
            token.value +
            "&user_id=" +
            localStorage.getItem("user_id")
        );
        // window.open("http://192.168.66.192:8080/#/?token=" + token.value+'&user_id='+localStorage.getItem('user_id'));
      } else {
        ElMessage.error("暂未开放");
      }
    };
    //监听vuex中的数据变化
    watch(
      () => {
        return store.state.setRobotData2;
      },
      async (newVal, oldVal) => {
        if (newVal) {
          nowsbeck2.value = true;
          // 如果newVal替换oldVal 为空 等于 done 时
          let len = answer[robot_chat_id_two.value].length - 1;
          let answerLen =
            answer[robot_chat_id_two.value][len].answer.length - 1;
          if (newVal.replace(oldVal, "") == "done" || newVal == "") {
            store.state.setRobotData2 = "";
            nowsbeck2.value = false;
            let item = answer[robot_chat_id_two.value][len];
            item.iconShow = true;
            item.current = answerLen;
            getqatime();
            // 获取回答id
            let res = await getmsghistory({
              page: page.value,
              size: 1,
              robot_chat_id: robot_chat_id_two.value,
            });
            let data = res.data.data.data;
            let i = data[0].answer.length - 1;
            item.answer[answerLen].id = data[0].answer[i].id;
            item.answer[answerLen].create_date = data[0].answer[i].create_date;
            return;
          }
          let newText = "";
          newText = newVal.replace("|", "\n");
          newText = newText.replaceAll("||", "|\n|");
          newText = newText.replace(/\|/g, "| ");
          let mapperHtml: any = marked(newText);
          mapperHtml = mapperHtml.replaceAll(
            "<table>",
            '<table class="chat-table">'
          );
          newText = mapperHtml;
          answer[robot_chat_id_two.value][len].answer[answerLen].text = newText;
          scrollBottm("questionref1");
        }
      }
    );
    watch(
      () => {
        return store.state.setRobotData;
      },
      async (newVal, oldVal) => {
        if (newVal) {
          // 如果newVal替换oldVal 为空 等于 done 时
          nowsbeck.value = true;
          let len = answer[robot_chat_id_one.value].length - 1;
          let answerLen =
            answer[robot_chat_id_one.value][len].answer.length - 1;
          if (newVal.replace(oldVal, "") == "done" || newVal == "") {
            store.state.setRobotData = "";
            nowsbeck.value = false;
            let item = answer[robot_chat_id_one.value][len];
            item.iconShow = true;
            item.current = answerLen;
            getqatime();
            // 获取回答id
            let res = await getmsghistory({
              page: page.value,
              size: 1,
              robot_chat_id: robot_chat_id_one.value,
            });
            let data = res.data.data.data;
            let i = data[0].answer.length - 1;
            item.answer[answerLen].id = data[0].answer[i].id;
            item.answer[answerLen].create_date = data[0].answer[i].create_date;
            return;
          }
          let newText = "";
          newText = newVal.replace("|", "\n");
          newText = newText.replaceAll("||", "|\n|");
          newText = newText.replace(/\|/g, "| ");
          let mapperHtml: any = marked(newText);
          mapperHtml = mapperHtml.replaceAll(
            "<table>",
            '<table class="chat-table">'
          );
          newText = mapperHtml;
          answer[robot_chat_id_one.value][len].answer[answerLen].text = newText;
          // answer[robot_chat_id_one.value][len].answer[answerLen].text =
          //   marked(newVal);
          if (rightshow.value == 2) {
            scrollBottm("questionref0");
          } else {
            scrollBottm("questionref");
          }
        }
      }
    );
    // 监听process.env.NODE_ENV = development
    // watch(
    //   () => {
    //     return process.env.NODE_ENV;
    //   },
    //   (newVal, oldVal) => {
    //     if (newVal == "development") {
    //       window.location.href = "about:blank";
    //     }
    //   }
    // );

    watch(
      () => token.value,
      (newVal) => {
        if (!newVal) {
          token.value = null;
        }
      },
      {
        immediate: true,
      }
    );
    const answerTemp1 = computed(() => {
      return answer["临时1"];
    });
    const answerTemp2 = computed(() => {
      return answer["临时2"];
    });
    onMounted(() => {
      if (
        !localStorage.getItem("first_register") &&
        localStorage.getItem("step1")
      ) {
        setTimeout(() => {
          registerGuideModal.value = true;
          localStorage.setItem("first_register", "y");
        }, 1000);
      }

      if (route.query.userRole) {
        let userRoleItem: any = route.query.userRole;
        userRole.value = JSON.parse(userRoleItem);
        localStorage.setItem("role", JSON.stringify(userRole.value));
        localStorage.setItem(
          "role_jump",
          encodeURIComponent(JSON.stringify(userRole.value))
        );
      }

      if (route.query.userName) {
        var userName: any = route.query.userName;
        var userId: any = route.query.userId;
        var itoken: any = route.query.token;
        localStorage.setItem("token", itoken);
        localStorage.setItem("userName", userName);
        localStorage.setItem("user_id", userId);
        token.value = itoken;
        username.value = userName;
        // 清除url参数
        router.replace({
          query: {},
        });
      }
      if (token.value) {
        getchatlists("none");
        robotlist("1");
        robotlist("2");
        robotlist("3");
        getmasklists();
        getqatime();
        // store.state.qatimeLoop = setInterval(() => {
        //   getqatime();
        // }, 10000);
        getmasklistall();
        getchangelogs();
      }
      modelslist();
      console.log(route);
    });
    return {
      closebutton,
      nowsbeck,
      nowsbeck2,
      //变量
      infojson,
      chattype,
      token,
      loginDialog,
      registerDialog,
      homeactive,
      username,
      userRole,
      ishistory,
      formLabelAlign,
      chattotal,
      total,
      origin,
      maskrobotListdialog,
      openselectdialog,
      historyheight,
      historyshowselect,
      historyselecttrue,
      morechattype,
      robot_chat_id_one,
      robot_chat_id_two,
      versiondialog,
      registerGuideModal,
      version,
      adddialogname,
      addrotname,
      addrotprompt,
      addrotmodel,
      addrotgreeting,
      adddescribe,
      addrotswitch,
      addavatar,
      usernameinput,
      drawer,
      lookpamartdialog,
      chatpageloading,
      myrobotdialog,
      robot,
      times,
      keydown1,
      keydown,
      keydown2,
      keydown3,
      questionref0,
      questionref,
      questionref1,
      homeactivelist,
      homemaskactive,
      homeaskindex,
      homemaskid,
      homemaskname,
      homemasklogo,
      homemaskactivedialog,
      registerjson,
      isinline,
      question,
      rightshow,
      homefocusin,
      answer,
      robotsearch,
      myrose,
      selectrobotlist,
      robotinfo,
      maskselectdialog,
      searchrobotlist,
      robotselect,
      rules,
      timess,
      isshowtimes,
      answerTemp1,
      answerTemp2,
      showRegisterModal,
      //方法
      login,
      registerHelp,
      register,
      logmore,
      robotsearchsub,
      loginuser,
      registeruser,
      productCards,
      sendcode,
      historyselectclick,
      clearhomeask,
      openhistory,
      collectrobot,
      historychat,
      focusin,
      openmyrobotcard,
      closemoretype,
      handleClose2,
      sendmessage3,
      robotlist,
      removerobts,
      homeselect,
      showhide,
      showhidedia,
      homemaskselect,
      delchats,
      editrobots,
      openDrawer,
      closerobot,
      sendmessage2,
      sendmessage,
      chatsendmessage,
      moresendmessage,
      handleCloseopen,
      addselectlist,
      chatidopen,
      uploadimgs,
      mathnowtime,
      tab,
      totab,
      handleInput,
      handleInput1,
      confirmEvent,
      masktab,
      addmaskmore,
      addselect,
      handleClose,
      getCouponSelected,
      createRobot,
      lookpamart,
      opennewchat,
      handleCopy,
      handleRegenerate,
      handleAnswerChange,
      openCardUrl,
      handleCreateDialog,
      handlePublic,
      registerFormRef,
      toOther,
    };
  },
});
</script>
<style lang="scss" scoped>
::v-deep .chat-table {
  text-align: left;
  table-layout: fixed;
  font-size: 12px;
  border-radius: 8px;
  overflow: hidden;
  border-collapse: collapse;
  margin: 7px 0;
  thead tr {
    background: #f2f4f7;
    transition: all 0.2s;
    &:hover {
      background: #dddee0;
      transition: all 0.2s;
    }
  }
  tbody tr:nth-child(odd) {
    background: rgba(255, 255, 255, 0.6);
  }
  th,
  td {
    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    white-space: nowrap;
  }
}
.common-layout {
  height: 100vh;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 50px;

  .logo {
    display: flex;
    align-items: center;

    img {
      height: 50px;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      color: #333;
      margin-left: 10px;
    }
  }

  .el-switch {
    margin-right: 10px;
  }
}

.aside {
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  text-align: center;
  line-height: 60px;
  color: #fff;
  padding: 10px 40px 10px 20px;

  .addrobot {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    // border-radius: 5px;
    // background: #416BE2;
    color: #333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    line-height: 50px;

    img {
      margin-right: 10px;
      margin-left: 5px;
    }
  }

  .asideitem {
    box-shadow: var(--el-box-shadow-light);
    border: 1px solid var(--el-border-color);
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 10px;
    // 文字只展示一行剩余省略
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  .active {
    background-color: #1d1e1f;
    color: #fff;
  }
}

.card {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow: hidden;
}
.card1 {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow: hidden;
}
.item_card {
  padding: 10px;
  border-radius: 30px;
  display: flex;
  background: #f4f9ff;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;

    span {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .button {
      padding: 10px;
      margin: 0;
      text-align: center;

      .el-icon {
        font-size: 12px;
      }
    }
  }

  .item {
    height: 100%;
    max-height: calc(100% - 70px);
    overflow: auto;

    // margin: 10px;
    .qus {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      font-size: 14px;

      div {
        padding: 10px;
        background: #2454ff;
        color: #fff;
        border-radius: 10px;
        max-width: 90%;
        width: max-content;
      }
    }

    .answer {
      display: flex;
      justify-content: flex-start;
      height: 100%;
      margin-bottom: 10px;
      max-width: 90%;
      font-size: 14px !important;

      .oneanswer {
        height: max-content;
      }

      .moreanswer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 20px;
      }
      img {
        width: 45px;
        height: 45px;
        margin-right: 10px;
        border-radius: 50%;
      }
      div {
        // padding: 10px;
        border-radius: 10px;
        // max-width: 100%;
      }
    }
  }
}

.drawertop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    cursor: pointer;
  }

  div:hover {
    // 下划线
    text-decoration: underline;
  }
}

.drawerform {
  margin-bottom: 20px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    font-weight: bold;
    margin: 10px 0;
  }

  p {
    font-size: 12px;
    margin: 5px 0;
    color: #999;
  }
}

.card_clum {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card_input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card_input_robot {
  display: flex;
  // align-items: center;
  // justify-content: center;
  margin: 20px 0 5px 10px;

  div {
    cursor: pointer;
    margin: 0 5px;
    border-radius: 25px;
    // border: 1px solid #b2b3b5;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px 10px;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  img {
    width: 25px;
    margin: 0 5px;
  }

  .active {
    background: #2454ff;
    color: #fff;
  }

  .active1 {
    background: #6ecc8e;
    color: #fff;
  }
}

.card_input_input1 {
  min-width: 600px;
  max-width: 600px;
  padding: 10px 0;

  div {
    // border: 1px solid #b2b3b5;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px 15px;
    border-radius: 20px;
    display: flex;
    width: max-content;
    background: #fff;
    span {
      color: #999;
    }
  }

  .card_input_input1_active {
    position: relative;
    border-color: #416be2;

    .close {
      padding: 0;
      width: 18px;
      height: 18px;
      position: absolute;
      top: -10px;
      background: #416be2;
      border: none;
      right: -10px;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.card_input_input {
  min-width: 600px;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: relative;
  .out_box1 {
    width: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    height: 74px;
    z-index: 9;
  }
  .out_box {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid transparent;
  }

  .inputsd {
    background: #3b3b3b;
  }

  .inputs {
    width: 100%;
    display: flex;
    // border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid #b2b3b5;
    min-height: 1.2rem;
    max-height: 8rem;
    border-radius: 50px;
    padding: 0.6rem 40px 0.6rem 5px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    textarea {
      border-color: #fff;
      outline: none;
      flex-grow: 1;
      border: none;
      resize: none;
      max-height: 7.5rem !important;
      width: calc(100% - 45px) !important;
      line-height: 25px;
      font-size: 14px;
      padding: 0 8px 0 12px;
    }
    .subtimes {
      position: absolute;
      right: 50px;
      bottom: 15px;
      font-size: 12px;
    }
    .subtimesactive {
      color: #d1431a;
    }

    textarea::-webkit-scrollbar {
      width: 5px;
    }

    textarea::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    textarea::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(190, 235, 231, 0.1);
    }
  }

  .subbtn1 {
    position: absolute;
    right: 50px;
    bottom: 0.5rem;
    // background: #AEADEE;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    cursor: pointer;
  }

  .subbtn {
    position: absolute;
    right: 5px;
    bottom: 0.5rem;
    // background: #aeadee;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
  }
}

.robotinfo {
  width: 250px;

  .robotinfo_name {
    display: flex;
    align-items: flex-end;

    div {
      width: calc(100% - 60px);
      padding-left: 10px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .robotinfo_collect {
    color: #a5acac;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    text-indent: 1rem;
  }

  .robotinfo_button {
    margin-top: 10px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.historyselect {
  display: flex;
  font-size: 14px;
  width: 50%;
  margin: 5px auto;
  div {
    border: 1px solid #999;
    border-radius: 30px;
    padding: 3px 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .historyactive {
    background: #2454ff;
    border: 1px solid #2454ff;
    color: #fff;
  }
}
.mychat {
  padding: 10px 15px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    width: calc(100% - 45px);

    p:nth-child(2) {
      color: #666;
      font-size: 14px;
    }
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.mychat_active {
  background: #eff1f7;
}

.mychat:hover {
  background: #e8eaf3;
  // color: #fff !important;
}
.mychathistory:hover {
  background: #2454ff;
  color: #fff !important;
}
.mychathistory:hover p:nth-child(2) {
  color: #fff !important;
}
// .mychat:hover p {
//   background: #416be2;
//   color: #fff !important;
// }

.mychat:hover .mychat_del {
  display: block;
}

.mychat_del {
  display: none;
  // color: red;
  position: relative;
  right: 45px;
  bottom: -15px;
}

.homebutton {
  width: 100%;
  padding: 5px 20px;
  line-height: 1;
  color: #ffffff;
  border: 1px solid #2454ff;
  color: #2454ff;
  // font-weight: 600;
  // border: 2px solid #3468f7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  height: 36px;
  img {
    width: 24px;
    margin-right: 10px;
  }
}

.maskdialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    margin-top: 10px;
    cursor: pointer;
    padding: 5px 10px;
    width: 150px;
    background: #3468f7;
    text-align: center;
    color: #fff;
    border-radius: 5px;
  }
}

// .header{
//     height: 50px;
// }
.product-card {
  width: 100%;
  height: 70px;
  margin: 14px 0;
  ul {
    display: flex;
    padding-left: 23px;
    color: #242424;
    .product-card-li {
      width: 250px;
      height: 70px;
      border-radius: 8px;
      margin-right: 27px;
      border: 1px solid rgba(187, 187, 187, 1);
      display: flex;
      transition: all 0.3s;
      &:hover {
        transition: all 0.25s;
        color: #fff;
        cursor: pointer;
      }
      .product-card__imgbox {
        border-radius: 50%;
        width: 46px;
        height: 46px;
        margin: 10px 12px 0;
        background: #fff;
        text-align: center;
        padding-top: 3px;
        img {
          width: 42px;
        }
      }
      .product-card__titles {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    .product-card-li-0 {
      color: #fff;
      cursor: pointer;
      background: linear-gradient(
        136.47deg,
        rgba(36, 63, 161, 1) 2.6%,
        rgba(36, 84, 255, 1) 99.97%
      );
      &:hover {
        box-shadow: 5px 10px 20px 0 #d5dcf9;
      }
    }
    .product-card-li-2 {
      &:hover {
        background: linear-gradient(
          135.53deg,
          rgba(49, 21, 122, 1) -1.27%,
          rgba(140, 35, 251, 1) 100.99%
        );
        box-shadow: 5px 10px 20px 0 #ebdef9;
      }
    }
    .product-card-li-1 {
      &:hover {
        background: linear-gradient(
          134.1deg,
          rgba(215, 101, 139, 1) 1.87%,
          rgba(254, 199, 141, 1) 98.38%
        );
        box-shadow: 5px 10px 20px 0px #fbe4e4;
      }
    }
  }
}
.container {
  height: 91vh;
  padding: 0 20px 10px 20px;
}

.card_select {
  padding: 10px 20px;

  .card_select_p {
    font-weight: 600;
    font-size: 18px;
    color: #333;
  }

  .card_select_div {
    display: flex;
    width: 100%;
    overflow: auto;
    margin-top: 20px;
    padding: 5px;
  }
  .card_select_div_tab {
    display: flex;
    align-items: center;

    div {
      padding: 5px 8px;
      margin: 20px 10px;
      cursor: pointer;
    }
    .active {
      border-bottom: 3px solid #2454ff;
    }
  }
  .card_select_item {
    height: 200px;
    width: 180px;
    min-width: 150px;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    .card_select_item_btns {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #2454ff;
      color: #fff;
      padding: 5px 0;
      border-radius: 10px;
      font-size: 14px;
      display: none;
    }
    div {
      text-align: center;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
      // font-weight: 600;
      color: #000;
      //超出两行省略
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .card_select_item:hover .card_select_item_btns {
    display: block;
  }

  // .active {
  //   background: #e1e9ff !important;
  // }
  .card_select_item1 > div:nth-child(2) {
    margin-left: 20px;
  }
  .card_select_item1 {
    height: 170px;
    min-width: 150px;
    // border:1px solid rgb(153, 169, 211);
    box-shadow: rgba(153, 169, 211, 0.5) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    display: flex;

    p {
      font-size: 18px;
      margin-top: 10px;
      font-weight: 600;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card_select_left {
      width: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .card_select_right {
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      // border:1px solid #99A9D3;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      cursor: pointer;
      border-radius: 10px;
      margin-left: 10px;
      overflow: hidden;

      .card_select_right_a {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #416be2;

        p {
          color: #ffffff;
        }
      }

      .card_select_right_a_button {
        padding: 5px 10px;
        font-size: 12px;
        background: #fff;
        color: #416be2;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .card_select_right_a_text {
        font-size: 12px;
        color: #fff;
        margin-bottom: 5px;
      }
    }
  }
}
.searchdialog {
  .card_select_div {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 5px;
  }
  .card_select_div_tab {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .card_select_div_item {
      padding: 5px 20px;
      margin: 0 10px 0 10px;
      cursor: pointer;
      border: 1px solid #bbbbbb;
      background: #fff;
      border-radius: 5px;
    }
    .active {
      // border-bottom:3px solid #2454FF;
      color: #fff;
      border: 1px solid #2454ff;
      background: #2454ff;
    }
  }
  .card_select_quesheng {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card_select_item {
    height: 200px;
    width: 180px;
    min-width: 150px;
    background: #fff;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid #c4c4c4;
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    .card_select_item_btns {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #2454ff;
      color: #fff;
      padding: 5px 0;
      border-radius: 10px;
      font-size: 14px;
      display: none;
    }
    .card_select_item_text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #999;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      div {
        display: flex;
        align-items: center;
      }
      img {
        width: 16px;
      }
    }
    div {
      text-align: center;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
      // font-weight: 600;
      color: #000;
      //超出两行省略
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .card_select_item:hover .card_select_item_btns {
    display: block;
  }
}
.myrobot {
  p {
    color: #333;

    span {
      color: #999;
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

.promptdiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
  }
}

.card_input_bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;

  .card_input_bottom_item {
    display: flex;
    align-items: center;
    width: 47%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #fff;
    padding: 20px 40px;
    border-radius: 15px;
    margin-top: 40px;

    img {
      width: 110px;
      margin-right: 20px;
      margin-left: 20px;
    }

    div {
      p:nth-child(1) {
        font-size: 18px;
        margin: 10px 0;
        font-weight: 600;
        color: #333;
      }
    }
  }
}

.gray {
  background: #8d9fce !important;
}
.box-card {
  padding: 0 10px;
  border-radius: 20px;
}
.item_card1 {
  padding: 10px;
  background: #f4f9ff;
  border-radius: 30px;
}
.select-header {
  background: #2454ff;
  padding: 20px 20px 0 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .select-header-div {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-weight: 600;
    .select-header-close {
      position: relative;
      top: -50px;
      right: -50px;
    }
  }
  .select-header-center {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding-bottom: 10px;
    div {
      display: flex;
      align-items: center;
      div {
        margin-right: 30px;
        padding: 8px 0;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 3px solid transparent;
      }
      .divactive {
        color: #fff;
        border-bottom: 3px solid #fff;
      }
    }
    .select-header-input {
      background: #fff;
      border-radius: 50px;
    }
  }
}
.stopbutton {
  border: 1px solid #4264e2;
  margin: 0 auto;
  width: max-content;
  border-radius: 15px;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  color: #4264e2;
  font-size: 14px;
  cursor: pointer;
}
.newchatshow {
  height: 56px;
  .newchatimg1 {
    display: none;
  }
  .newchatimg {
    display: block;
  }
}
.newchatshow:hover {
  .newchatimg1 {
    display: block;
  }
  .newchatimg {
    display: none;
  }
}
.mychat_del_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px !important;
  height: 25px;
  border-radius: 50%;
  background: #fff;
}
.bottom_info {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  align-items: center;
  margin: 0 auto;
  color: #a7a7a7;
  padding-bottom: 10px;
  font-size: 12px;
  div {
    display: flex;
    align-items: center;
    img {
      width: 15px;
      margin: 0 5px;
    }
  }
}
.titleandtime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  .vtitle {
    font-size: 18px;
    font-weight: 600;
  }
  .ttitle {
    color: #333;
  }
}
.register-guide-dialog {
  .register-container {
    .register-tips {
      font-size: 16px;
      font-weight: bold;
    }
    .register-guide-text {
      font-size: 12px;
      margin: 5px 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.select-header-input ::v-deep .el-input {
  padding: 0 !important;
}
.select-header-input ::v-deep .el-input__wrapper {
  border: none !important;
  box-shadow: none !important;
}
.select-dialog ::v-deep .el-dialog__header {
  padding: 0 !important;
  margin: 0 !important;
}
.select-dialog ::v-deep .el-dialog__body {
  background: #f4f9ff !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-radius: 4px;
  padding-top: 18px;
}

.select-dialog ::v-deep .el-dialog {
  border-radius: 10px !important;
  /* overflow: hidden !important; */
}
.infocard ::v-deep .el-card__body {
  padding: 10px 5px !important;
}
::v-deep .select-trigger {
  height: 100%;
}

::v-deep .el-input {
  height: 100%;
}

.item_card1 ::v-deep .box-card-body {
  padding: 0px 20px 0 20px !important;
  height: calc(100% - 41px) !important;
}

.item_card2 ::v-deep .box-card-body {
  height: 100% !important;
}

::v-deep .el-card__header {
  padding: 10px 20px;
}

.card ::v-deep .el-card {
  height: calc(100% - 115px) !important;
}
.item_card1 ::v-deep .el-card {
  height: 100% !important;
}

::v-deep .el-card__body {
  height: inherit;
}

::v-deep .el-collapse {
  border-top: none;
}

::v-deep .el-collapse-item__content {
  padding: 0;
}
/*滚动条样式*/
::-webkit-scrollbar {
  width: 0;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(190, 235, 231, 0.1);
}

::v-deep .markdown-body {
  font-size: 14px;
  overflow-x: auto;
  .icon-box {
    display: flex;
    align-items: center;
    gap: 10px;
    .pagination {
      display: flex;
      align-items: center;
      gap: 2px;
      .subs {
        font-size: 12px;
      }
    }
    .icon {
      cursor: pointer;
      color: #a3a3a3;
    }
    .icon:hover {
      color: #000;
    }
    .disable {
      color: #a3a3a3;
    }
  }
}
</style>
