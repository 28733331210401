import request from "@/utils/request";
import qs from "qs";
/**
 * 用户登录
 */
export function logins(data: any) {
  return request({
    url: "personlogin/",
    method: "post",
    data: qs.stringify(data),
  });
}
/**
 * 获取通道
 */
export function getChannel() {
  return request({
    url: "changliao/chat/getchannel",
    method: "post",
  });
}
/**
 * 机器人问答
 */
export function robotqs(data: any) {
  return request({
    url: "changliao/chat/question",
    method: "post",
    data: data,
  });
}

/**
 * 心跳
 */
export function heartbeat(data: any) {
  return request({
    url: "changliao/chat/heartbeat",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 获取机器人列表
 */
export function getrobotlist(data: any) {
  return request({
    url: "changliao/robot/list",
    method: "get",
    params: data,
  });
}

/**
 * 获取机器人模型下拉列表
 */
export function getmodelslist(data: any) {
  return request({
    url: "changliao/robot/models",
    method: "get",
    params: data,
  });
}

/**
 * 添加机器人
 */
export function addrobot(data: any) {
  return request({
    url: "changliao/robot/add",
    method: "post",
    data: data,
  });
}

/**
 * 获取机器人消息历史
 */
export function getmsghistory(data: any) {
  return request({
    url: "changliao/robot/msghistory",
    method: "get",
    params: data,
  });
}

// 停止回答
export function stopreply(params: any) {
  return request({
    url: "changliao/chat/stopreply",
    method: "get",
    params,
  });
}

/**
 * 获取机器人信息
 */
export function getrobotinfo(data: any) {
  return request({
    url: "changliao/robot/info",
    method: "get",
    params: data,
  });
}
/**
 * 获取机器人删除
 */
export function delrobot(data: any) {
  return request({
    url: "changliao/robot/del",
    method: "post",
    data: qs.stringify(data),
  });
}

export function editrobot(data: any) {
  return request({
    url: "changliao/robot/edit",
    method: "post",
    data: data,
  });
}
/**
 * 上传图片
 */
export function uploadimg(data: any) {
  return request({
    url: "changliao/common/uploadimg",
    method: "post",
    data: data,
  });
}

/**
 * 创建会话
 */
export function createchat(data: any) {
  return request({
    url: "changliao/chat/createchat",
    method: "post",
    data: data,
  });
}

/**
 * 获取会话列表
 */
export function getchatlist(data: any) {
  return request({
    url: "changliao/chat/robotchatlist",
    method: "get",
    params: data,
  });
}
/**
 * 收藏
 */
export function collect(data: any) {
  return request({
    url: "changliao/robot/collect",
    method: "post",
    data: qs.stringify(data),
  });
}
/**
 * 删除会话
 */
export function delchat(data: any) {
  return request({
    url: "changliao/chat/delchat",
    method: "post",
    data: qs.stringify(data),
  });
}
/**
 * 获取面具列表
 */
export function getmasklist(data: any) {
  return request({
    url: "changliao/robot/masklist",
    method: "get",
    params: data,
  });
}
/**
 * 机器人聊天记录
 */
export function getrobotmsghistory(data: any) {
  return request({
    url: "changliao/robot/getchatlist",
    method: "get",
    params: data,
  });
}

/**
 * 清除上下文
 */
export function clearhistory(data: any) {
  return request({
    url: "changliao/chat/clearhistory",
    method: "get",
    params: data,
  });
}
/**
 * 获取问答次数
 */
export function getqatimes() {
  return request({
    url: "changliao/chat/getqatimes",
    method: "get",
  });
}
/**
 * 注册
 */
export function registerapi(data: any) {
  return request({
    url: "register/",
    method: "post",
    data: qs.stringify(data),
  });
}
/**
 * 发送验证码
 */
export function sendmsg(data: any) {
  return request({
    url: "sendmsg/",
    method: "post",
    data: qs.stringify(data),
  });
}
/**
 * ip登录
 */
export function iplogin() {
  return request({
    url: "iplogin/",
    method: "get",
  });
}

/**
 * 获取更新日志
 */
export function getchangelog() {
  return request({
    url: "changliao/chat/getchangelog",
    method: "get",
  });
}
