import axios from "axios";
import store from "@/store";
import { useRouter } from "vue-router";
import { ElMessage, ElPopconfirm } from "element-plus";
var router = useRouter();
const service = axios.create({
  //    baseURL: "/api",
  //  baseURL: window.location.origin + '/api/', // url = base url + request url
  // baseURL: "http://ai.dic.cool/api/", // url = base url + request url
  baseURL: store.state.baseURL, // url = base url + request  url
  timeout: 50000, // 请求超时时间
  // withCredentials: true, // 允许携带cookie
});
service.interceptors.request.use(
  (config) => {
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  (err) => {
    return err;
  }
);

service.interceptors.response.use(
  (res) => {
    const specApi = ["changliao/robot/models"];
    const specWay_noToken = specApi.includes(<string>res.config.url || ""); // 特殊情况特殊处理，如要解决此类问题，还需和后端协调出单独的业务code，区分登录和未登录接口的调用返回处理

    if (res.data.code == 400 && !specWay_noToken) {
      localStorage.removeItem("token");
      localStorage.clear();
      store.dispatch("closeQatimeLoop", false);
      router.replace({
        query: {},
      });
      // window.location.href = 'https://chatlibrary.newacademic.net/changliao/#/'
      ElMessage.error(res.data.msg);
      return res;
    }
    return res;
  },
  (err) => {
    return err;
  }
);

export default service;
